import dayjs from 'dayjs';
import { BlobServiceClient } from '@azure/storage-blob';
import AzureService from '@/services/azure';

const generateUniqueId = () => {
  const datePart = dayjs().format('YYYYMMDDHHmmssSSS');
  const randomPart = Math.floor(Math.random() * 1000)
    .toString()
    .padStart(4, '0');
  return `${datePart}${randomPart}`;
};

export const getSasToken = async () => {
  try {
    const { data } = await AzureService.getSasToken();

    return data;
  } catch (error) {
    alert(error);
    console.error(error);
  }
};

export const uploadFileToAzure = async (file, containerName = 'images') => {
  try {
    // Storage Service connection
    const sasToken = await getSasToken();
    const blobServiceClient = new BlobServiceClient(sasToken, null);

    // Container connection
    const containerClient = blobServiceClient.getContainerClient(containerName);

    // create blob block
    const blobName = `${generateUniqueId()}${file.name}`;
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);

    // Blob upload
    await blockBlobClient.uploadData(file, { blobHTTPHeaders: { blobContentType: file.type } });
    const uploadedfileUrl = `https://${process.env.VUE_APP_STORAGE_ACCOUNT_NAME}.blob.core.windows.net/${containerName}/${blobName}`;

    return uploadedfileUrl;
  } catch (error) {
    alert(error);
    console.error(error);
  }
};
