<template>
  <div class="coupon-manager-wrapper">
    <h1 class="page-title">쿠폰 {{ couponId ? '수정' : '생성' }}</h1>

    <div class="coupon-contents-wrapper">
      <CruiseInputField label="쿠폰명">
        <div class="field-wrapper">
          <CruiseInput class="input-width-large" placeholder="한글 입력" v-model="coupon.couponNameKo" />
          <CruiseInput class="input-width-large" placeholder="일본어 입력" v-model="coupon.couponNameJa" />
          <CruiseInput class="input-width-large" placeholder="영어 입력" v-model="coupon.couponNameEn" />
        </div>
      </CruiseInputField>

      <CruiseInputField label="쿠폰 형식">
        <div class="field-wrapper">
          <CruiseRadio
            class="checkbox-width"
            v-model="coupon.couponType"
            v-for="item in couponTypeList"
            :key="item.code"
            :label="getLocalText(item, 'name')"
            :value="item.code"
            name="couponType"
          />
        </div>
      </CruiseInputField>

      <CruiseInputField label="쿠폰 코드">
        <div class="field-wrapper">
          <CruiseInput class="input-width-large" placeholder="쿠폰 코드" v-model="coupon.couponCode" />
        </div>
      </CruiseInputField>

      <CruiseInputField label="발행 수량">
        <div class="field-wrapper">
          <CruiseInput
            class="input-width-large"
            placeholder="발행 수량 입력"
            v-model.number="coupon.issuePerCouponCount"
            :disabled="isInfinite"
            type="number"
          />
          <CruiseCheckbox label="개수 제한 없음" v-model="isInfinite" />
        </div>
      </CruiseInputField>

      <CruiseInputField label="항로">
        <div class="field-wrapper">
          <CruiseSelect
            class="select-width"
            placeholder="항로"
            :options="routeList"
            v-model="coupon.routeCode"
            property="code"
            @onChange="handleRouteCode"
          >
            <template #option="{ item }">
              {{ getLocalText(item, 'name') }}
            </template>
          </CruiseSelect>
        </div>
      </CruiseInputField>

      <CruiseInputField label="할인 코드">
        <div class="field-wrapper">
          <CruiseSelect
            class="select-width"
            placeholder="선택"
            :options="saleCodeList"
            v-model="coupon.saleCode"
            :disabled="!coupon.routeCode"
            property="code"
            @onChange="handleSaleCode"
          >
            <template #option="{ item }">
              {{ item.code }}
            </template>
          </CruiseSelect>
          <span v-if="!coupon.routeCode" class="alert-text">항로를 먼저 선택하세요.</span>
        </div>
      </CruiseInputField>

      <CruiseInputField label="혜택">
        <div class="field-wrapper">
          <CruiseInput class="input-width" placeholder="비율 할인" readonly v-model="coupon.discountRate">
            %
          </CruiseInput>
          <span class="alert-text">할인코드에 따른 고정값(입력불가)</span>
        </div>
      </CruiseInputField>

      <CruiseInputField label="사용 조건(승객 노출 문구)">
        <div class="textarea-field-wrapper field-wrapper">
          <CruiseTextArea class="input-width-large" placeholder="한글 입력" v-model="coupon.conditionDescriptionKo" />
          <CruiseTextArea class="input-width-large" placeholder="일본어 입력" v-model="coupon.conditionDescriptionJa" />
          <CruiseTextArea class="input-width-large" placeholder="영어 입력" v-model="coupon.conditionDescriptionEn" />
        </div>
      </CruiseInputField>

      <CruiseInputField label="사용 조건">
        <div class="w-100 flex-column">
          <div class="condition-item">
            <CruiseCheckbox class="checkbox-width" label="항차" v-model="isVoyage" />

            <CruiseSelect
              class="select-width-large"
              :options="vesselList"
              v-model="voyageDetail.vessel.code"
              property="code"
              :disabled="!isVoyage"
            >
              <template #option="{ item }">
                {{ getLocalText(item, 'name') }}
              </template>
            </CruiseSelect>
            <CruiseSelect
              class="select-width"
              :options="departurePortList"
              v-model="voyageDetail.departurePort.code"
              property="code"
              :disabled="!isVoyage"
            >
              <template #option="{ item }">
                {{ getLocalText(item, 'name') }}
              </template>
            </CruiseSelect>
            <CruiseSelect
              class="select-width"
              :options="arrivalPortList"
              v-model="voyageDetail.arrivalPort.code"
              property="code"
              :disabled="!isVoyage"
            >
              <template #option="{ item }">
                {{ getLocalText(item, 'name') }}
              </template>
            </CruiseSelect>
            <CruiseSelect
              class="select-width"
              :options="tripTypeList"
              v-model="voyageDetail.isRound"
              property="code"
              :disabled="!isVoyage"
            >
              <template #option="{ item }">
                {{ item.name }}
              </template>
            </CruiseSelect>
            <CruiseDatePicker
              class="input-width-large"
              v-model:dateRange="voyageDate"
              placeholder="시작 출항일 - 종료 출항일"
              range
              :disabled="!isVoyage"
            />
          </div>

          <div class="condition-item">
            <CruiseCheckbox class="checkbox-width" label="객실" v-model="isCabin" />
            <CruiseSelect
              class="select-width"
              placeholder="객실 추가"
              v-model="curGrade"
              :options="gradeList"
              :disabled="!isCabin"
              @onChange="addGrade"
            >
              <template #option="{ item }">
                {{ item.gradeCode }}
              </template>
            </CruiseSelect>

            <div class="selected-grade-list">
              <div class="grade-item" v-for="(grade, idx) in selectedGradeList" :key="grade.code">
                <span>
                  {{ grade.gradeCode }}
                </span>

                <CloseIcon class="close-icon" @click="deleteGrade(idx)" :size="16" />
              </div>
            </div>
          </div>

          <div class="condition-item">
            <CruiseCheckbox class="checkbox-width" label="승객 나이" v-model="isPassengerAge" />
            <CruiseDatePicker
              class="input-width-large"
              placeholder="시작 나이 - 종료 나이"
              v-model:dateRange="ageRange"
              :disabled="!isPassengerAge"
              range
            />
          </div>

          <div class="condition-item">
            <CruiseCheckbox class="checkbox-width" label="한국어" v-model="couponDetails.isKorean" />
            <CruiseCheckbox class="checkbox-width" label="일본어" v-model="couponDetails.isJapanese" />
            <CruiseCheckbox class="checkbox-width" label="영어" v-model="couponDetails.isEnglish" />
          </div>

          <div class="condition-item">
            <CruiseCheckbox class="checkbox-width" label="n+k 할인" disabled />
            <span class="alert-text">준비 중 입니다.</span>
          </div>
        </div>
      </CruiseInputField>

      <CruiseInputField label="사용 기간">
        <div class="field-wrapper">
          <CruiseDatePicker
            class="input-width-large"
            v-model:dateRange="availableRange"
            placeholder="시작 사용일 - 종료 사용일"
            :disabled="isNotRange"
            range
          />
          <CruiseCheckbox class="checkbox-width" label="기간 제한 없음" v-model="isNotRange" />
        </div>
      </CruiseInputField>

      <CruiseInputField label="ID 1개당 최대 발행 개수">
        <div class="field-wrapper">
          <CruiseInput
            class="input-width-large"
            placeholder="숫자 입력"
            v-model.number="coupon.maxIssuePerUserCount"
            type="number"
          >
            회
          </CruiseInput>
        </div>
      </CruiseInputField>
    </div>

    <div class="btn-wrapper">
      <CruiseButton class="select-width" @click="saveCoupon">{{ couponId ? '수정' : '저장' }}</CruiseButton>
      <CruiseButton v-if="!couponId" class="select-width" theme="s" @click="refresh">초기화</CruiseButton>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { mapMutations } from 'vuex';
import { getLocalText } from '@/utils/convert';
import { regExp } from '@/utils/validator';
import { YYYY_MM_DD } from '@/const/const';

import CouponService from '@/services/coupon';
import RouteService from '@/services/route';
import PortService from '@/services/port';
import VesselService from '@/services/vessel';
import GradeService from '@/services/grade';

import CloseIcon from 'vue-material-design-icons/Close';
import CruiseCheckbox from '@/components/common/CruiseCheckbox.vue';
import CruiseInput from '@/components/common/CruiseInput.vue';
import CruiseInputField from '@/components/common/CruiseInputField.vue';
import CruiseRadio from '@/components/common/CruiseRadio.vue';
import CruiseSelect from '@/components/common/CruiseSelect.vue';
import CruiseDatePicker from '@/components/common/CruiseDatePicker.vue';
import CruiseButton from '@/components/button/CruiseButton.vue';
import CruiseTextArea from '@/components/common/CruiseTextArea.vue';

export default {
  name: 'CouponRegister',
  components: {
    CruiseInputField,
    CruiseInput,
    CruiseRadio,
    CruiseCheckbox,
    CruiseSelect,
    CruiseDatePicker,
    CloseIcon,
    CruiseButton,
    CruiseTextArea,
  },
  data() {
    return {
      regExp,

      // input data
      coupon: {
        // 쿠폰명
        couponNameKo: '',
        couponNameJa: '',
        couponNameEn: '',
        couponType: 'COUPON',
        couponCode: '',
        issuePerCouponCount: null,
        routeCode: '',
        saleCode: null,
        discountRate: null,
        conditionDescriptionKo: '',
        conditionDescriptionJa: '',
        conditionDescriptionEn: '',
        maxIssuePerUserCount: null,
      },

      voyageDate: [],
      ageRange: [],
      availableRange: [],

      couponDetails: {
        isKorean: true,
        isJapanese: true,
        isEnglish: true,
      },
      voyageDetail: {
        vessel: { code: '' },
        departurePort: { code: '' },
        arrivalPort: { code: '' },
        isRound: null,
      },

      isInfinite: false,
      isNotRange: false,

      isVoyage: false,
      isCabin: false,
      isPassengerAge: false,

      curGrade: null,
      selectedGradeList: [],

      // async data
      couponTypeList: [],
      saleCodeList: [],
      routeList: [],
      departurePortList: [{ code: '', nameKo: '출항지', nameJa: '出港地', nameEn: 'Port of Departure' }],
      arrivalPortList: [{ code: '', nameKo: '도착지', nameJa: '到着地', nameEn: 'Port of Arrival' }],
      vesselList: [{ code: '', nameKo: '선박', nameJa: '船', nameEn: 'Vessel' }],
      gradeList: [],
      tripTypeList: [
        { code: null, name: '왕복 여부' },
        { code: true, name: '왕복' },
        { code: false, name: '편도' },
      ],
    };
  },
  computed: {
    couponId() {
      return this.$route.query.couponId;
    },
  },
  watch: {
    isInfinite(value) {
      if (value) {
        this.coupon.issuePerCouponCount = null;
      }
    },
  },
  methods: {
    ...mapMutations('common', ['setIsLoading']),
    getLocalText,

    async init() {
      try {
        this.setIsLoading(true);
        await this.getCouponType();
        await this.getRouteList();
        await this.getPortList();
        await this.getVesselList();
        await this.getGradeList();
        await this.initializeCoupon();
      } catch (error) {
        console.error(error);
      } finally {
        this.setIsLoading(false);
      }
    },
    async getCouponType() {
      try {
        const { data } = await CouponService.getCouponType();
        this.couponTypeList = data;
      } catch (error) {
        alert(error);
      }
    },
    async getSaleCodeByRouteCode(routeCode) {
      try {
        const { data } = await CouponService.getSaleCodeByRouteCode(routeCode);
        this.saleCodeList = data;
      } catch (error) {
        alert(error);
      }
    },
    async getRouteList() {
      try {
        const { data } = await RouteService.getRouteList();
        this.routeList = data;
      } catch (error) {
        alert(error);
      }
    },
    async getPortList() {
      try {
        const { data } = await PortService.getPortList();
        this.departurePortList = [...this.departurePortList, ...data];
        this.arrivalPortList = [...this.arrivalPortList, ...data];
      } catch (error) {
        alert(error);
      }
    },
    async getVesselList() {
      try {
        const { data } = await VesselService.getVesselList();
        this.vesselList = [...this.vesselList, ...data];
      } catch (error) {
        alert(error);
      }
    },
    async getGradeList() {
      try {
        const { data } = await GradeService.getGradeList();
        this.gradeList = data.map(item => ({ id: item.grade.id, gradeCode: item.grade.gradeCode }));
      } catch (error) {
        alert(error);
      }
    },
    handleRouteCode(routeCode) {
      this.saleCodeList = [];
      this.coupon.saleCode = null;
      this.coupon.discountRate = null;
      this.getSaleCodeByRouteCode(routeCode);
    },
    handleSaleCode(_, item) {
      this.coupon.discountRate = item.dcRate;
    },
    canSave() {
      if (!this.couponDetails.isKorean && !this.couponDetails.isJapanese && !this.couponDetails.isEnglish) {
        alert('사용 조건 언어를 1개 이상 선택해주세요.');
        return false;
      }

      if (this.couponDetails.isKorean) {
        if (!this.coupon.couponNameKo || !this.coupon.conditionDescriptionKo) {
          alert('한국어 항목을 모두 입력해주세요.');
          return false;
        }
      }

      if (this.couponDetails.isJapanese) {
        if (!this.coupon.couponNameJa || !this.coupon.conditionDescriptionJa) {
          alert('일본어 항목을 모두 입력해주세요.');
          return false;
        }
      }

      if (this.couponDetails.isEnglish) {
        if (!this.coupon.couponNameEn || !this.coupon.conditionDescriptionEn) {
          alert('영어 항목을 모두 입력해주세요.');
          return false;
        }
      }

      return true;
    },
    async saveCoupon() {
      if (!this.canSave()) {
        return;
      }

      try {
        this.setIsLoading(true);
        const formatRange = range => (range ? range.map(this.formatDate) : []);
        const [availableDateFrom, availableDateTo] = formatRange(this.availableRange);
        const [departureDateFrom, departureDateTo] = formatRange(this.voyageDate);
        const [ageFrom, ageTo] = formatRange(this.ageRange);
        const { vessel, departurePort, arrivalPort, isRound } = this.voyageDetail;

        const body = {
          ...this.coupon,
          availableDateFrom: !this.isNotRange ? availableDateFrom || '' : '',
          availableDateTo: !this.isNotRange ? availableDateTo || '' : '',
          saleCode: this.coupon.saleCode || '',
          issuePerCouponCount: this.isInfinite ? 0 : this.coupon.issuePerCouponCount,
          isLimited: !this.coupon.maxIssuePerUserCount ? false : !this.isInfinite,
          maxIssuePerUserCount: this.coupon.maxIssuePerUserCount || 0,

          couponDetails: [
            {
              // 항차
              ...(this.isVoyage && vessel && { vessel }),
              ...(this.isVoyage && departurePort && { departurePort }),
              ...(this.isVoyage && arrivalPort && { arrivalPort }),
              ...(this.isVoyage && isRound !== null && { isRound }),
              departureDateFrom: this.isVoyage ? departureDateFrom || '' : '',
              departureDateTo: this.isVoyage ? departureDateTo || '' : '',

              // 객실
              ...(this.isCabin && { grades: this.selectedGradeList }),

              // 나이
              ageFrom: this.isPassengerAge ? ageFrom || '' : '',
              ageTo: this.isPassengerAge ? ageTo || '' : '',

              ...this.couponDetails,
            },
          ],
        };

        if (this.couponId) {
          await CouponService.updateCoupon(body);
        } else {
          await CouponService.postCouponWithDetail(body);
        }
        alert('저장되었습니다.');
        this.$router.push('/coupon');
      } catch (error) {
        alert(error);
        console.error(error);
      } finally {
        this.setIsLoading(false);
      }
    },
    refresh() {
      window.location.reload();
    },
    formatDate(date) {
      return date ? dayjs(date).format(YYYY_MM_DD) : '';
    },

    addGrade(grade, _, e) {
      e.target.selectedIndex = 0;
      this.selectedGradeList = this.selectedGradeList.filter(item => item.gradeCode !== grade.gradeCode);
      this.selectedGradeList.push(grade);
    },
    deleteGrade(idx) {
      this.selectedGradeList.splice(idx, 1);
    },
    // update coupon
    async initializeCoupon() {
      if (!this.couponId) {
        return;
      }

      try {
        this.setIsLoading(true);
        const { data } = await CouponService.getCouponById(this.couponId);
        await this.getSaleCodeByRouteCode(data.routeCode);

        this.coupon = data;
        this.isInfinite = data.issuePerCouponCount === 0;
        this.availableRange = [data.availableDateFrom, data.availableDateTo];
        this.isNotRange = !data.availableDateFrom;
        this.voyageDate = [data.couponDetails[0].departureDateFrom, data.couponDetails[0].departureDateTo];
        this.ageRange = [data.couponDetails[0].ageFrom, data.couponDetails[0].ageTo];
        this.voyageDetail = {
          vessel: data.couponDetails[0].vessel,
          departurePort: data.couponDetails[0].departurePort,
          arrivalPort: data.couponDetails[0].arrivalPort,
          isRound: data.couponDetails[0].isRound,
        };
        this.selectedGradeList = data.couponDetails[0].grades;
        this.couponDetails = {
          isKorean: data.couponDetails[0].isKorean,
          isEnglish: data.couponDetails[0].isEnglish,
          isJapanese: data.couponDetails[0].isJapanese,
        };
        this.checkVoyageConditions();
        this.checkCabinConditions();
        this.checkPassengerAgeConditions();
      } catch (error) {
        alert(error);
        console.error(error);
      } finally {
        this.setIsLoading(false);
      }
    },
    checkVoyageConditions() {
      const details = this.coupon.couponDetails[0];
      this.isVoyage =
        !!details.vessel.code ||
        !!details.departurePort.code ||
        !!details.arrivalPort.code ||
        details.isRound !== null ||
        !!details.departureDateFrom;
    },
    checkCabinConditions() {
      this.isCabin = this.coupon.couponDetails[0].grades?.length > 0;
    },
    checkPassengerAgeConditions() {
      const details = this.coupon.couponDetails[0];
      this.isPassengerAge = !!details.ageFrom || !!details.ageTo;
    },
    getPlaceHolder(list, value) {
      return value ? list.find(item => item.code === value)?.name : null;
    },
  },
  created() {
    this.init();
  },
};
</script>

<style scoped>
.coupon-manager-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 24px;
}

.coupon-contents-wrapper {
  display: flex;
  flex-direction: column;
  border: 0.5px solid #f0f0f0;
}

.field-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 20px;
}

.textarea-field-wrapper {
  height: 100px;
  padding: 0 20px;
}

.input-width,
.select-width {
  flex-shrink: 0;
  width: 120px;
}

.input-width-large {
  width: 250px !important;
}

.select-width-large {
  flex-shrink: 0;
  width: 200px;
}

.checkbox-width {
  min-width: 90px;
}

.alert-text {
  color: #f00;
  font-size: 12px;
  font-weight: 400;
}

.condition-item {
  width: 100%;
  height: 60px;
  flex-shrink: 0;
  border-bottom: 0.5px solid #f0f0f0;
  padding-left: 20px;
  border-left: none;
  background: #fff;
  display: flex;
  align-items: center;
  gap: 10px;
}

.condition-item:last-child {
  border-bottom: none;
}

.selected-grade-list {
  display: flex;
  gap: 5px;
  width: 100%;
  height: 40px;
}

.grade-item {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 40px;
}

.close-icon {
  cursor: pointer;
  border-radius: 50%;
  color: #fff;
  background: #d9d9d9;
}

.btn-wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
</style>
