<template>
  <MainHeader />
  <div class="main-container">
    <SideNav />
    <div class="content-wrapper">
      <RouterView />
    </div>
  </div>
  <CruiseLoading />
</template>

<script>
import MainHeader from '@/layouts/MainHeader.vue';
import SideNav from '@/layouts/SideNav.vue';
import CruiseLoading from '@/components/spinner/CruiseLoading.vue';

export default {
  name: 'MainContainer',
  components: { MainHeader, SideNav, CruiseLoading },
};
</script>

<style scoped>
.main-container {
  flex: 1 0;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
}

.content-wrapper {
  flex: 1 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 10px 20px 0;
}
</style>
