<template>
  <div class="coupon-mgmt-wrapper">
    <div class="flex-between">
      <h1 class="page-title">쿠폰 관리</h1>

      <CruiseButton @click="goToAddCoupon">쿠폰 만들기</CruiseButton>
    </div>

    <div class="coupon-list-wrapper">
      <div class="search-filter">
        <CruiseSelect class="select-width" :options="searchList" v-model="searchType" property="key">
          <template #option="{ item }">
            {{ item.label }}
          </template>
        </CruiseSelect>

        <CruiseInput
          class="input-width"
          placeholder="검색어 입력"
          v-model="searchValue"
          @keyup.enter="searchCouponList"
        />

        <CruiseButton theme="s" @click="refresh">초기화</CruiseButton>
        <CruiseButton @click="searchCouponList">검색</CruiseButton>

        <CruiseButton class="delete-btn" theme="q" @click="removeCouponList">삭제</CruiseButton>
      </div>

      <CruiseTabs v-model:activeTab="activeTab">
        <CruiseTab
          v-for="(label, i) in tabLabel"
          :title="`${label} ${couponData.length > 0 ? couponData[i].count : 0}`"
          :key="label"
        >
        </CruiseTab>
      </CruiseTabs>

      <div class="coupon-table">
        <CruiseTable
          :theadList="thead"
          :tbodyList="coupon.coupons"
          @onAllCheck="handleAllCheck"
          @clickRow="goToUpdatePage"
        >
          <template #checkbox="{ item }">
            <CruiseCheckbox v-model="item.isSelected" />
          </template>
          <template #availableDate="{ item }">
            <div class="text-ellipsis" :title="availableDate(item)">
              {{ availableDate(item) }}
            </div>
          </template>
          <template #usedCount="{ item }">
            <div class="text-ellipsis" :title="usedCountLabel(item)">
              {{ usedCountLabel(item) }}
            </div>
          </template>
          <template #createdAt="{ item }">
            <div class="text-ellipsis" :title="formatDate(item.createdAt)">
              {{ formatDate(item.createdAt) }}
            </div>
          </template>
          <template #updatedAt="{ item }">
            <div class="text-ellipsis" :title="formatDate(item.updatedAt)">
              {{ formatDate(item.updatedAt) }}
            </div>
          </template>
          <template #remark="{ item }">
            <CruiseButton class="update-btn" theme="q" @click="goToUpdatePage(item)">수정</CruiseButton>
          </template>
        </CruiseTable>
      </div>

      <CruisePagination :page="tabList[activeTab].page" :pages="coupon.pages" @update:page="handlePage" />
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { mapMutations } from 'vuex';
import { YYYY_MM_DD_HH_MM_SS } from '@/const/const';
import { getLocalKey } from '@/utils/convert';
import CouponService from '@/services/coupon';

import CruiseButton from '@/components/button/CruiseButton.vue';
import CruiseInput from '@/components/common/CruiseInput.vue';
import CruiseTab from '@/components/tab/CruiseTab.vue';
import CruiseTabs from '@/components/tab/CruiseTabs.vue';
import CruiseTable from '@/components/table/CruiseTable.vue';
import CruiseCheckbox from '@/components/common/CruiseCheckbox.vue';
import CruisePagination from '@/components/common/CruisePagination.vue';
import CruiseSelect from '@/components/common/CruiseSelect.vue';

export default {
  name: 'CouponManagement',
  components: {
    CruiseButton,
    CruiseInput,
    CruiseTabs,
    CruiseTab,
    CruiseTable,
    CruiseCheckbox,
    CruisePagination,
    CruiseSelect,
  },
  data() {
    return {
      limit: 10,
      activeTab: 0,
      isSearch: false,
      searchValue: '',
      searchType: 'couponName',
      searchList: [
        { key: 'couponName', label: '쿠폰명', value: '' },
        { key: 'couponCode', label: '쿠폰코드', value: '' },
      ],

      tabList: [
        { status: '', page: 1 },
        { status: 'PND', page: 1 },
        { status: 'IPG', page: 1 },
        { status: 'CMP', page: 1 },
      ],
      couponData: [],
    };
  },
  watch: {
    activeTab() {
      this.tabList.map(item => {
        item.page = 1;
      });
    },
  },
  computed: {
    tabLabel() {
      return ['전체', '대기', '진행중', '종료'];
    },
    thead() {
      return [
        { key: 'checkbox' },
        { label: '쿠폰명', key: this.getLocalKey('couponName'), css: { width: '200px' } },
        { label: '사용혜택', key: 'discountRate', css: { width: '70px' } },
        { label: '사용조건', key: this.getLocalKey('conditionDescription'), css: { width: '200px' } },
        { label: '상태', key: this.getLocalKey('status'), css: { width: '100px' } },
        { label: '쿠폰코드', key: 'couponCode', css: { width: '100px' } },
        { label: '사용/발행', key: 'usedCount', css: { width: '100px' } },
        { label: '사용기간', key: 'availableDate', css: { width: '200px' } },
        { label: '등록일', key: 'createdAt', css: { width: '150px' } },
        { label: '수정일', key: 'updatedAt', css: { width: '150px' } },
        { label: '', key: 'remark', css: { width: '60px' } },
      ];
    },
    coupon() {
      return this.couponData[this.activeTab] || {};
    },
    selectedCouponList() {
      return this.couponData[this.activeTab].coupons.filter(item => item.isSelected);
    },
  },
  methods: {
    ...mapMutations('common', ['setIsLoading']),
    getLocalKey,

    init() {
      this.getTotalCouponList();
    },
    async getCouponList() {
      try {
        this.setIsLoading(true);
        const item = this.tabList[this.activeTab];
        const query = {
          status: item.status,
          couponName: this.searchType === 'couponName' ? this.searchValue : null,
          couponCode: this.searchType === 'couponCode' ? this.searchValue : null,
          page: item.page,
          limit: this.limit,
        };
        const { data } = await CouponService.getCouponList(query);
        this.couponData[this.activeTab] = data;
      } catch (error) {
        alert(error);
        console.error(error);
      } finally {
        this.setIsLoading(false);
      }
    },
    async getTotalCouponList() {
      try {
        this.setIsLoading(true);
        const promises = this.tabList.map(item => {
          const query = {
            status: item.status,
            couponName: this.searchValue ? this.searchValue : null,
            couponCode: this.searchValue ? this.searchValue : null,
            page: item.page,
            limit: this.limit,
          };
          return CouponService.getCouponList(query);
        });
        const results = await Promise.all(promises);
        this.couponData = results.map(res => res.data);
      } catch (error) {
        alert(error);
        console.error(error);
      } finally {
        this.setIsLoading(false);
      }
    },
    async removeCouponList() {
      if (this.selectedCouponList.length === 0) {
        alert('삭제할 쿠폰을 선택해주세요.');
        return;
      }

      try {
        this.setIsLoading(true);
        const query = {
          couponId: this.selectedCouponList.map(item => item.id).join(','),
        };
        await CouponService.deleteCoupon(query);

        alert('삭제되었습니다.');
        await this.getCouponList();
      } catch (error) {
        alert(error);
        console.error(error);
      } finally {
        this.setIsLoading(false);
      }
    },
    formatDate(date) {
      return dayjs(date).format(YYYY_MM_DD_HH_MM_SS);
    },
    availableDate({ availableDateFrom, availableDateTo }) {
      return availableDateFrom ? `${availableDateFrom} ~ ${availableDateTo}` : '제한없음';
    },
    usedCountLabel({ issuedCount, issuePerCouponCount }) {
      return issuePerCouponCount ? `${issuedCount}회/${issuePerCouponCount}회` : '제한없음';
    },
    goToAddCoupon() {
      this.$router.push('/coupon/create');
    },
    goToUpdatePage(coupon) {
      this.$router.push({ path: '/coupon/update', query: { couponId: coupon.id } });
    },
    handlePage(page) {
      this.tabList[this.activeTab].page = page;
      this.getCouponList();
    },
    handleAllCheck(checked) {
      this.couponData[this.activeTab].coupons.forEach(coupon => {
        coupon.isSelected = checked;
      });
    },
    refresh() {
      if (this.isSearch) {
        window.location.reload();
        this.isSearch = false;
      }
    },
    async searchCouponList() {
      this.isSearch = true;
      this.tabList.map(item => {
        item.page = 1;
      });
      await this.getTotalCouponList();
      await this.getCouponList();
    },
  },
  created() {
    this.init();
  },
};
</script>

<style scoped>
.coupon-mgmt-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.search-input {
  width: 660px;
  margin-right: 20px;
}

.coupon-list-wrapper {
  position: relative;
  border-radius: 5px;
  background: #fff;
  min-height: 560px;
}

.coupon-table {
  min-height: 460px;
  overflow: auto;
}

.delete-btn {
  margin-left: 65px;
}

.update-btn {
  width: 40px;
  height: 27px;
}

.input-width {
  width: 250px;
}

.search-filter {
  display: flex;
  gap: 10px;
  position: absolute;
  z-index: var(--zContentsMain);
  top: 10px;
  right: 20px;
}

.select-width {
  width: 120px;
}
</style>
