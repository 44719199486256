<template>
  <header class="main-header">
    <div class="logo-wrapper flex-center">
      <RouterLink to="/home" class="flex-center">
        <img src="@/assets/logo/panstar_full_logo.svg" alt="panstar cruise logo" />
      </RouterLink>
    </div>

    <div class="select-wrapper">
      <CruiseSelect
        class="select-item"
        v-model="$i18n.locale"
        :options="languageList"
        property="locale"
        @onChange="handleLocaleChange"
      >
        <template #option="{ item }">
          {{ item.label }}
        </template>
      </CruiseSelect>

      <UserNav />
    </div>
  </header>
</template>

<script>
import CruiseSelect from '@/components/common/CruiseSelect.vue';
import { setItem } from '@/utils/localStorage';
import UserNav from '@/layouts/UserNav.vue';

export default {
  name: 'MainHeader',
  components: { CruiseSelect, UserNav },
  data() {
    return {
      languageList: [
        {
          locale: 'ko',
          label: '한국어',
        },
        {
          locale: 'ja',
          label: '일본어',
        },
        {
          locale: 'en',
          label: '영어',
        },
      ],
    };
  },
  methods: {
    handleLocaleChange(locale) {
      setItem('userLanguage', locale);
      window.location.reload();
    },
  },
};
</script>

<style scoped>
.main-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background: var(--White);
  border: var(--default--border);
}

.logo-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.select-item {
  width: 160px;
  flex-shrink: 0;
}

.select-wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}
</style>
