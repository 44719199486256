<template>
  <div class="input-field-wrapper">
    <CruiseLabel class="label-field" :label="label" :helperText="helperText" :required="required" />

    <slot></slot>
  </div>
</template>

<script>
import CruiseLabel from '@/components/common/CruiseLabel.vue';

export default {
  name: 'CruiseInputField',
  components: { CruiseLabel },
  props: {
    label: String,
    helperText: String,
    required: Boolean,
  },
};
</script>

<style scoped>
.input-field-wrapper {
  width: 100%;
  display: flex;
  background: #fff;
  border-top: 0.5px solid #f0f0f0;
}

.input-field-wrapper:first-child {
  border-top: none;
}

.label-field {
  flex-shrink: 0;
  min-width: 260px;
  max-width: 260px;
  padding: 20px;
  border-right: 1px solid #f0f0f0;
  height: 100%;
}
</style>
