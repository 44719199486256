import { createStore } from 'vuex';
import common from '@/store/modules/common';
import auth from '@/store/modules/auth';

export default createStore({
  modules: {
    common,
    auth,
  },
});
