<template>
  <li v-if="distributed.header" class="tab" :class="tabClass" @click="navClick">
    <slot name="title">
      <span>{{ title }}</span>
    </slot>
    <div v-show="isActive && distributed.theme !== 'group'" class="active-border"></div>
  </li>

  <template v-else>
    <div v-show="isActive" v-bind="$attrs">
      <slot></slot>
    </div>
  </template>
</template>

<script>
export default {
  name: 'CruiseTab',
  inheritAttrs: false,
  inject: ['tabs', 'distributed'],
  props: {
    title: String,
  },
  mounted() {
    this.index = Array.from(this.$parent.$el.children).indexOf(this.$el);
  },
  data() {
    return {
      index: null,
    };
  },
  computed: {
    isActive() {
      return this.tabs.activeTab === this.index;
    },
    tabClass() {
      return {
        group: this.distributed.theme === 'group',
        blue: this.distributed.theme === 'blue',
        active: this.isActive,
      };
    },
  },
  methods: {
    navClick() {
      if (!this.isActive) {
        this.distributed.changeTabTo(this.index);
      }
    },
  },
};
</script>

<style scoped>
/* 탭 테마 : 기본 */
.tab {
  height: 40px;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  padding: 13px 8px;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #969696;
  min-width: 70px;
}

.tab.active {
  color: var(--Black);
  font-weight: 700;
}

/* 탭 테마 : 그룹 */
.tab.group {
  width: 120px;
  height: 40px;
  color: var(--Black);
  border: var(--default--border);
  background: var(--White);
}

.tab.group:first-child {
  border-radius: 5px 0 0 5px;
}

.tab.group:last-child {
  border-radius: 0 5px 5px 0;
}

.tab.group.active {
  color: var(--White);
  font-weight: 400;
  border: 1px solid var(--Main);
  background: var(--Main);
}

/* 탭 테마 : 블루 */
.tab.blue {
  width: 80px;
  min-width: 80px;
  height: 40px;
}

.tab.blue.active {
  color: var(--Main);
}

.tab.blue .active-border {
  left: 0;
  width: 100%;
  border-radius: 0;
  background: var(--Main);
}

div {
  width: 100%;
  height: 100%;
}

.active-border {
  position: absolute;
  bottom: 0;
  left: 8px;
  width: calc(100% - 16px);
  height: 3px;
  background: var(--Black);
  border-radius: 5px 5px 0 0;
}

@media screen and (min-width: 768px) {
  .tab {
    height: 60px;
    font-size: 16px;
    padding: 10px 20px;
    min-width: 120px;
  }

  /* 탭 테마 : 블루 */
  .tab.blue {
    padding: 0;
    min-width: 80px;
  }

  .active-border {
    position: absolute;
    bottom: 0;
    left: 10px;
    width: calc(100% - 20px);
    height: 3px;
    background: var(--Black);
    border-radius: 5px 5px 0 0;
  }
}
</style>
