<template>
  <div class="notice-register-wrapper flex-column">
    <h1 class="page-title">공지사항 {{ noticeId ? '수정' : '등록' }}</h1>

    <div class="notice-contents-wrapper">
      <CruiseInputField label="제목">
        <div class="field-wrapper">
          <CruiseInput class="input-width" placeholder="한국어 입력" v-model="notice.titleKo" />
          <CruiseInput class="input-width" placeholder="일본어 입력" v-model="notice.titleJa" />
          <CruiseInput class="input-width" placeholder="영어 입력" v-model="notice.titleEn" />
        </div>
      </CruiseInputField>

      <CruiseInputField label="노출 대상">
        <div class="field-wrapper">
          <CruiseCheckbox class="checkbox-width" label="한국어" v-model="notice.isOpenedKo" />
          <CruiseCheckbox class="checkbox-width" label="일본어" v-model="notice.isOpenedJa" />
          <CruiseCheckbox class="checkbox-width" label="영어" v-model="notice.isOpenedEn" />
        </div>
      </CruiseInputField>

      <CruiseInputField label="분류">
        <div class="field-wrapper">
          <CruiseSelect
            class="select-width"
            :placeholder="getLocalText(categoryList, 'category')"
            :options="categoryList"
            v-model="category"
          >
            <template #option="{ item }">
              {{ getLocalText(item, 'category') }}
            </template>
          </CruiseSelect>
        </div>
      </CruiseInputField>

      <CruiseInputField label="내용">
        <CruiseTabs v-model:activeTab="activeTab" theme="group">
          <CruiseTab v-for="(tab, i) in tabList" :title="tab.label" :key="tab.code">
            <div class="editor-wrapper flex-column">
              <QuillEditor
                v-if="i === 0"
                v-model:content="notice.contentKo"
                contentType="html"
                placeholder="내용을 입력해주세요."
                toolbar="essential"
              />
              <QuillEditor
                v-if="i === 1"
                v-model:content="notice.contentJa"
                contentType="html"
                placeholder="内容を入力してください。"
                toolbar="essential"
              />
              <QuillEditor
                v-if="i === 2"
                v-model:content="notice.contentEn"
                contentType="html"
                placeholder="Please enter the content."
                toolbar="essential"
              />
            </div>
          </CruiseTab>
        </CruiseTabs>
      </CruiseInputField>
    </div>

    <div class="btn-wrapper">
      <CruiseButton class="select-width" @click="saveNotice">{{ noticeId ? '수정' : '저장' }}</CruiseButton>
      <CruiseButton class="select-width" theme="s" @click="cancel">취소</CruiseButton>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { getLocalText, getLocalKey } from '@/utils/convert';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import NoticeService from '@/services/notice';

import CruiseInputField from '@/components/common/CruiseInputField.vue';
import CruiseInput from '@/components/common/CruiseInput.vue';
import CruiseCheckbox from '@/components/common/CruiseCheckbox.vue';
import CruiseSelect from '@/components/common/CruiseSelect.vue';
import CruiseButton from '@/components/button/CruiseButton.vue';
import CruiseTabs from '@/components/tab/CruiseTabs.vue';
import CruiseTab from '@/components/tab/CruiseTab.vue';

export default {
  name: 'NoticeRegister',
  components: {
    QuillEditor,
    CruiseInputField,
    CruiseInput,
    CruiseCheckbox,
    CruiseSelect,
    CruiseButton,
    CruiseTabs,
    CruiseTab,
  },
  data() {
    return {
      categoryList: [
        { categoryKo: '공지', categoryJa: 'お知らせ', categoryEn: 'Notice' },
        { categoryKo: '운항 변경', categoryJa: '運航変更', categoryEn: 'Flight Changes' },
      ],
      notice: {
        titleKo: '',
        titleJa: '',
        titleEn: '',
        isOpenedKo: true,
        isOpenedJa: true,
        isOpenedEn: true,
        author: '',
        contentKo: '',
        contentJa: '',
        contentEn: '',
      },
      category: { categoryKo: '공지', categoryJa: 'お知らせ', categoryEn: 'Notice' },

      activeTab: 0,
      tabList: [
        { code: 'Ko', label: '한국어' },
        { code: 'Ja', label: '일본어' },
        { code: 'En', label: '영어' },
      ],
    };
  },
  computed: {
    ...mapState('auth', ['user']),

    noticeId() {
      return this.$route.query.id;
    },
  },
  methods: {
    ...mapMutations('common', ['setIsLoading']),
    getLocalText,

    init() {
      this.getNoticeDetail();
    },
    async getNoticeDetail() {
      if (!this.noticeId) return;

      try {
        this.setIsLoading(true);

        const { data } = await NoticeService.getNoticeDetail(this.noticeId);
        this.notice = data;
        this.category = this.categoryList.find(item => item.categoryKo === this.notice.categoryKo);
      } catch (error) {
        alert(error);
      } finally {
        this.setIsLoading(false);
      }
    },
    canSave() {
      if (!this.notice.isOpenedKo && !this.notice.isOpenedJa && !this.notice.isOpenedEn) {
        alert('노출 대상 언어를 1개 이상 선택해주세요.');
        return false;
      }

      if (this.notice.isOpenedKo) {
        if (!this.notice.titleKo || !this.notice.contentKo) {
          alert('한국어 항목을 모두 입력해주세요.');
          return false;
        }
      }

      if (this.notice.isOpenedJa) {
        if (!this.notice.titleJa || !this.notice.contentJa) {
          alert('일본어 항목을 모두 입력해주세요.');
          return false;
        }
      }

      if (this.notice.isOpenedEn) {
        if (!this.notice.titleEn || !this.notice.contentEn) {
          alert('영어 항목을 모두 입력해주세요.');
          return false;
        }
      }

      return true;
    },
    async saveNotice() {
      if (!this.canSave()) {
        return;
      }

      try {
        this.setIsLoading(true);

        const categoryTextByLocal = getLocalKey('category');
        const selectedCategory = this.categoryList.find(
          item => item[categoryTextByLocal] === this.category[categoryTextByLocal],
        );

        this.notice = {
          ...this.notice,
          id: this.noticeId ? Number(this.noticeId) : null,
          author: this.noticeId ? this.notice.author : this.user.userId,
          updater: this.noticeId ? this.user.userId : null,
          categoryKo: selectedCategory.categoryKo,
          categoryJa: selectedCategory.categoryJa,
          categoryEn: selectedCategory.categoryEn,
          contentKo: this.notice.contentKo ? this.$sanitize(this.notice.contentKo) : null,
          contentJa: this.notice.contentJa ? this.$sanitize(this.notice.contentJa) : null,
          contentEn: this.notice.contentEn ? this.$sanitize(this.notice.contentEn) : null,
        };

        if (this.noticeId) {
          await NoticeService.setNotice(this.notice);
        } else {
          await NoticeService.addNotice(this.notice);
        }

        alert(`공지사항 ${this.noticeId ? '수정' : '등록'}이 완료되었습니다.`);
        this.$router.push('/notice');
      } catch (error) {
        alert(`공지사항 ${this.noticeId ? '수정' : '등록'} 중 오류가 발생했습니다.`);
        console.error(error);
      } finally {
        this.setIsLoading(false);
      }
    },
    cancel() {
      const result = confirm('확인을 누르시면 작성한 내용은 저장되지 않습니다.\n취소하시겠습니까?');
      if (result) {
        this.$router.push('/notice');
      }
    },
  },
  created() {
    this.init();
  },
};
</script>

<style scoped>
.notice-contents-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.field-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 20px;
}

.input-width {
  width: 250px !important;
}

.select-width {
  flex-shrink: 0;
  width: 120px;
}

.btn-wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.editor-wrapper {
  padding: 30px 20px 10px;
  height: 100%;
  min-height: 320px;
}

:deep(.tab-nav.group) {
  margin: 10px 20px 0;
}
</style>
