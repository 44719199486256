<template>
  <label class="custom-checkbox" @click.stop>
    <input
      type="checkbox"
      hidden
      v-bind="attrs"
      :id="safeId"
      :checked="isChecked"
      @change="handleChange"
      :disabled="disabled"
    />
    <span class="checkmark flex-center" :class="markClass">
      <CheckBold class="check-icon" :size="14" v-if="isChecked" />
    </span>
    <span v-if="label" class="checkbox-label">
      {{ label }}
      <span v-if="required" class="required-star">*</span>
    </span>
  </label>
</template>

<script>
import { sharedComputedProps } from '@/mixins/formMixins';
import CheckBold from 'vue-material-design-icons/CheckBold';

export default {
  name: 'CruiseCheckbox',
  mixins: [sharedComputedProps],
  emits: ['update:modelValue', 'onChange'],
  components: { CheckBold },
  props: {
    label: String,
    modelValue: Boolean,
    disabled: Boolean,
    checked: Boolean,
    required: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    markClass() {
      return [{ checked: this.checked || !!this.modelValue, disabled: this.disabled }];
    },
    isChecked() {
      return this.checked || !!this.modelValue;
    },
  },
  methods: {
    handleChange(e) {
      this.$emit('update:modelValue', e.target.checked);
      this.$emit('onChange', e);
    },
  },
};
</script>

<style scoped>
.custom-checkbox {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  font-size: 14px;
  user-select: none;
}

.checkmark {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  border-radius: 2.5px;
  border: 1px solid var(--Disabled, #9299a0);
}

.checkmark.checked {
  border: 1px var(--Blue-blue-500, #446ceb);
  background: var(--Blue-blue-500, #446ceb);
}

.checkmark.disabled {
  cursor: default;
  border: 1px solid var(--Grey-grey-100, #dddfe2);
}

.checkmark.checked.disabled {
  border: 1px var(--Blue-blue-100, #c5d1f9);
  background: var(--Blue-blue-100, #c5d1f9);
}

.check-icon {
  color: var(--White);
}

.checkbox-label {
  display: inline-flex;
  align-items: center;
}

.required-star {
  color: var(--Red);
}
</style>
