import api from '@/services/api';

const API_URL = '/api/admin';

class UserService {
  async login(params) {
    try {
      await api.post(`${API_URL}/login`, params);

      return true;
    } catch (error) {
      throw error;
    }
  }
}

export default new UserService();
