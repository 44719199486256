<template>
  <div class="select-wrapper">
    <select
      ref="selector"
      name="selector"
      class="selector text-ellipsis"
      :class="[selectClass, disabledClass]"
      @change="handleChange"
      @click="handleClick"
      :disabled="disabled"
    >
      <option v-if="placeholder" disabled selected>{{ placeholder }}</option>
      <option v-for="(item, index) in options" :key="index" :value="getItemValue(item)" :selected="isSelectItem(item)">
        <template v-if="$slots.option">
          <slot name="option" :item="item" :index="index"></slot>
        </template>
        <span v-else>{{ optionContent(item) }}</span>
      </option>
    </select>
    <span class="dropdown-icon-wrapper" :class="disabledClass">
      <ChevronDownIcon class="dropdown-icon" :size="24" />
    </span>
  </div>
</template>

<script>
import ChevronDownIcon from 'vue-material-design-icons/ChevronDown';

export default {
  name: 'CruiseSelect',
  components: { ChevronDownIcon },
  emits: ['update:modelValue', 'onClick', 'onChange'],
  props: {
    modelValue: [String, Number, Object, Boolean],
    property: String,
    options: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: String,
  },
  computed: {
    selectClass() {
      return {
        label: this.modelValue === '' || this.modelValue === null || this.modelValue === undefined,
      };
    },
    disabledClass() {
      return {
        'gray-text': this.disabled,
      };
    },
  },
  watch: {
    modelValue(newValue) {
      if (newValue === null || newValue === undefined || newValue === '') {
        this.resetSelectOptions();
      }
    },
    options() {
      this.resetSelectOptions();
    },
  },
  methods: {
    handleClick(e) {
      this.$emit('onClick', e);
    },
    handleChange(e) {
      const item = JSON.parse(e.target.value);
      const value = this.property ? item[this.property] : item;
      this.$emit('update:modelValue', value);
      this.$emit('onChange', value, item, e);
    },
    getItemValue(item) {
      return JSON.stringify(item);
    },
    optionContent(item) {
      return typeof item === 'object' && !!this.property ? item[this.property] : item;
    },
    isSelectItem(item) {
      return this.property
        ? item[this.property] === this.modelValue
        : JSON.stringify(item) === JSON.stringify(this.modelValue);
    },
    resetSelectOptions() {
      this.$refs.selector.selectedIndex = 0;
    },
  },
};
</script>

<style scoped>
.select-wrapper {
  width: 100%;
  height: 40px;
  position: relative;
  font-size: 12px;
}

.selector {
  width: 100%;
  height: 100%;
  padding-left: 10px;
  padding-right: 30px;
  color: var(--Black);
  font-size: unset;
  text-align: start;
  border-radius: 5px;
  border: 1px solid var(--Grey-grey-100, #dddfe2);
  background: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
}

.selector:focus,
.selector:active {
  border: 1px solid var(--Blue-blue-500, #446ceb);
}

.selector:disabled {
  pointer-events: none;
}

.selector.label {
  color: #969696;
}

.dropdown-icon-wrapper {
  color: var(--Black);
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  cursor: pointer;
  pointer-events: none;
}

.dropdown-icon {
  width: 24px;
  height: 24px;
}

@media screen and (min-width: 768px) {
  .select-wrapper {
    font-size: 14px;
  }

  .dropdown-icon-wrapper {
    right: 10px;
  }
}
</style>
