<template>
  <div class="event-mgmt-wrapper">
    <div class="flex-between">
      <h1 class="page-title">이벤트 관리</h1>
      <CruiseButton @click="goToRegisterPage">신규 등록</CruiseButton>
    </div>

    <div class="event-list-wrapper">
      <CruiseButton class="delete-btn" theme="q" @click="removeEventList">삭제</CruiseButton>

      <CruiseTabs v-model:activeTab="activeTab">
        <CruiseTab
          v-for="(tab, i) in tabLabel"
          :title="`${tab.label} ${eventList.length > 0 ? eventList[i][tab.key] : 0}`"
          :key="tab.key"
        >
        </CruiseTab>
      </CruiseTabs>

      <div class="event-table">
        <CruiseTable
          :theadList="thead"
          :tbodyList="event.events"
          @onAllCheck="handleAllCheck"
          @clickRow="goToUpdatePage"
        >
          <template #checkbox="{ item }">
            <CruiseCheckbox v-model="item.isSelected" />
          </template>
          <template #eventId="{ item }">
            <div class="text-ellipsis" :title="item.id">
              {{ item.id }}
            </div>
          </template>
          <template #title="{ item }">
            <div class="text-ellipsis" :title="getLocalText(item, 'name')">
              {{ getLocalText(item, 'name') }}
            </div>
          </template>
          <template #status="{ item }">
            <div class="text-ellipsis" :title="getLocalText(item, 'status')">
              {{ getLocalText(item, 'status') }}
            </div>
          </template>
          <template #category="{ item }">
            <div class="text-ellipsis" :title="getLocalText(item, 'category')">
              {{ getLocalText(item, 'category') }}
            </div>
          </template>
          <template #availablePeriod="{ item }">
            <div class="text-ellipsis" :title="availablePeriod(item.startDate, item.endDate)">
              {{ availablePeriod(item.startDate, item.endDate) }}
            </div>
          </template>
          <template #remark="{ item }">
            <CruiseButton class="update-btn" theme="q" @click="goToUpdatePage(item)">수정</CruiseButton>
          </template>
        </CruiseTable>
      </div>
      <CruisePagination :page="tabList[activeTab].page" :pages="event.pages" @update:page="handlePage" />
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { getLocalText, formatDateRange } from '@/utils/convert';

import EventService from '@/services/event';

import CruiseButton from '@/components/button/CruiseButton.vue';
import CruiseTabs from '@/components/tab/CruiseTabs.vue';
import CruiseTab from '@/components/tab/CruiseTab.vue';
import CruiseTable from '@/components/table/CruiseTable.vue';
import CruiseCheckbox from '@/components/common/CruiseCheckbox.vue';
import CruisePagination from '@/components/common/CruisePagination.vue';

export default {
  name: 'EventManagement',
  components: { CruiseButton, CruiseTabs, CruiseTab, CruiseTable, CruiseCheckbox, CruisePagination },
  data() {
    return {
      activeTab: 0,

      eventList: [],
      tabList: [
        { isOn: null, statusKo: '', statusJa: '', statusEn: '', page: 1 },
        { isOn: true, statusKo: '진행 중', statusJa: '進行中', statusEn: 'In Progress', page: 1 },
        { isOn: false, statusKo: '종료', statusJa: '終了', statusEn: 'End', page: 1 },
      ],
    };
  },
  computed: {
    tabLabel() {
      return [
        { key: 'total', label: '전체' },
        { key: 'countProceed', label: '진행 중' },
        { key: 'countEnd', label: '종료' },
      ];
    },
    thead() {
      return [
        { key: 'checkbox' },
        { label: 'ID', key: 'eventId', css: { width: '40px' } },
        { label: '제목', key: 'title', css: { width: '300px' } },
        { label: '상태', key: 'status', css: { width: '120px' } },
        { label: '분류', key: 'category', css: { width: '120px' } },
        { label: '적용기간', key: 'availablePeriod', css: { width: '300px' } },
        { label: '등록일', key: 'createdAt', css: { width: '120px' } },
        { label: '등록자', key: 'createdUser', css: { width: '120px' } },
        { label: '수정일', key: 'updatedAt', css: { width: '120px' } },
        { label: '수정자', key: 'updatedUser', css: { width: '120px' } },
        { label: '', key: 'remark', css: { width: '60px' } },
      ];
    },
    event() {
      return this.eventList[this.activeTab] || {};
    },
  },
  methods: {
    ...mapMutations('common', ['setIsLoading']),
    getLocalText,
    formatDateRange,

    async getEventList() {
      try {
        this.setIsLoading(true);

        const item = this.tabList[this.activeTab];
        const params = {
          isOn: item.isOn,
          page: item.page || 1,
          limit: 10,
        };
        const { data } = await EventService.getEventList(params);
        this.eventList[this.activeTab] = data;
      } catch (error) {
        alert(error);
      } finally {
        this.setIsLoading(false);
      }
    },
    async getTotalEventList() {
      try {
        this.setIsLoading(true);

        const promises = this.tabList.map(item => {
          const params = {
            isOn: item.isOn,
            page: item.page || 1,
            limit: 10,
          };
          return EventService.getEventList(params);
        });
        const results = await Promise.all(promises);
        this.eventList = results.map(res => res.data);
      } catch (error) {
        alert(error);
        console.error(error);
      } finally {
        this.setIsLoading(false);
      }
    },
    goToRegisterPage() {
      this.$router.push('/event/create');
    },
    goToUpdatePage(event) {
      this.$router.push({ path: '/event/update', query: { id: event.id } });
    },
    handlePage(page) {
      this.tabList[this.activeTab].page = page;
      this.getEventList();
    },
    handleAllCheck(checked) {
      this.eventList[this.activeTab].events.forEach(event => {
        event.isSelected = checked;
      });
    },
    async removeEventList() {
      try {
        this.setIsLoading(true);

        const checkedList = this.eventList[this.activeTab].events
          .filter(event => event.isSelected)
          .map(event => event.id);

        if (!checkedList.length) {
          alert('삭제할 이벤트를 선택해주세요.');
          return;
        }

        const params = {
          ids: checkedList.join(','),
        };
        await EventService.removeEventList(params);

        alert('삭제되었습니다.');
        await this.getEventList();
      } catch (error) {
        alert(error);
      } finally {
        this.setIsLoading(false);
      }
    },
    availablePeriod(startDate, endDate) {
      const setEndDate = endDate !== 'Invalid Date' ? endDate : '';
      return setEndDate ? this.formatDateRange(startDate, endDate) : '상시진행';
    },
  },
  created() {
    this.getTotalEventList();
  },
};
</script>

<style scoped>
.event-mgmt-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.event-list-wrapper {
  position: relative;
  border-radius: 5px;
  min-height: 560px;
  background: var(--White);
}

.delete-btn {
  position: absolute;
  z-index: var(--zContentsMain);
  top: 10px;
  right: 20px;
}

.header-title-text > span {
  color: #969696;
  font-size: 16px;
  font-weight: 400;
  margin-left: 70px;
}

.event-table {
  min-height: 460px;
  overflow: auto;
}

.update-btn {
  width: 40px;
  height: 27px;
}
</style>
