import UserService from '@/services/user';
import CookieManager from '@/utils/cookie';
import { decodeJWT } from '@/utils/jwt';

const state = {
  status: { loggedIn: false },
  user: null,
};

const mutations = {
  loginSuccess(state, user) {
    state.status.loggedIn = true;
    state.user = user;
  },
  loginFailure(state) {
    state.status.loggedIn = false;
    state.user = null;
  },
  logout(state) {
    state.status.loggedIn = false;
    state.user = null;
  },
};

const actions = {
  async login({ commit, dispatch }, user) {
    try {
      await UserService.login(user);
      dispatch('isLoggedIn');
      return true;
    } catch (error) {
      commit('loginFailure');
      throw error;
    }
  },
  logout({ commit }) {
    const cookieManager = new CookieManager();
    cookieManager.deleteCookie('adminUserInfo');
    commit('logout');
  },
  isLoggedIn({ commit }) {
    const cookieManager = new CookieManager();
    const accessToken = cookieManager.getCookie('adminUserInfo');

    if (accessToken) {
      const userInfo = decodeJWT(accessToken);
      commit('loginSuccess', userInfo);
    } else {
      commit('logout');
    }
  },
};

const getters = {};

const auth = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default auth;
