<template>
  <div class="notice-mgmt-wrapper">
    <div class="flex-between">
      <h1 class="page-title">공지사항 관리</h1>
      <CruiseButton @click="goToRegisterPage">신규 등록</CruiseButton>
    </div>

    <div class="notice-list-wrapper">
      <div class="header-title flex-between">
        <div class="header-title-text">
          공지사항 <span>총 {{ totalNoticeCount }}개</span>
        </div>
        <CruiseButton class="delete-btn" theme="q" @click="removeNoticeList">삭제</CruiseButton>
      </div>

      <div class="notice-table">
        <CruiseTable :theadList="thead" :tbodyList="noticeList" @onAllCheck="handleAllCheck" @clickRow="goToUpdatePage">
          <template #checkbox="{ item }">
            <CruiseCheckbox v-model="item.isSelected" />
          </template>
          <template #title="{ item }">
            <div class="text-ellipsis" :title="getLocalText(item, 'title')">
              {{ getLocalText(item, 'title') }}
            </div>
          </template>
          <template #category="{ item }">
            <div class="text-ellipsis" :title="getLocalText(item, 'category')">
              {{ getLocalText(item, 'category') }}
            </div>
          </template>
          <template #remark="{ item }">
            <CruiseButton class="update-btn" theme="q" @click="goToUpdatePage(item)">수정</CruiseButton>
          </template>
        </CruiseTable>
      </div>
      <CruisePagination :page="activePage" :pages="totalPage" @update:page="handlePage" />
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { getLocalText } from '@/utils/convert';

import NoticeService from '@/services/notice';

import CruiseButton from '@/components/button/CruiseButton.vue';
import CruiseTable from '@/components/table/CruiseTable.vue';
import CruiseCheckbox from '@/components/common/CruiseCheckbox.vue';
import CruisePagination from '@/components/common/CruisePagination.vue';

export default {
  name: 'NoticeManagement',
  components: { CruiseButton, CruiseTable, CruiseCheckbox, CruisePagination },
  data() {
    return {
      noticeList: [],
      totalNoticeCount: 0,

      totalPage: 0,
      activePage: 1,
    };
  },
  computed: {
    thead() {
      return [
        { key: 'checkbox' },
        { label: '제목', key: 'title', css: { width: '300px' } },
        { label: '분류', key: 'category', css: { width: '100px' } },
        { label: '등록일', key: 'createdAt', css: { width: '120px' } },
        { label: '등록자', key: 'author', css: { width: '120px' } },
        { label: '수정일', key: 'updatedAt', css: { width: '120px' } },
        { label: '수정자', key: 'updater', css: { width: '120px' } },
        { label: '', key: 'remark', css: { width: '60px' } },
      ];
    },
  },
  methods: {
    ...mapMutations('common', ['setIsLoading']),
    getLocalText,

    async getNoticeList(page) {
      try {
        this.setIsLoading(true);

        const params = {
          page: page || 1,
          limit: 10,
        };

        const { data } = await NoticeService.getNoticeList(params);

        this.noticeList = data.notices;
        this.totalPage = data.pages;
        this.totalNoticeCount = data.count;
      } catch (error) {
        alert(error);
      } finally {
        this.setIsLoading(false);
      }
    },
    goToRegisterPage() {
      this.$router.push('/notice/create');
    },
    goToUpdatePage(notice) {
      this.$router.push({ path: '/notice/update', query: { id: notice.id } });
    },
    handlePage(page) {
      this.activePage = page;
      this.getNoticeList(page);
    },
    handleAllCheck(checked) {
      this.noticeList?.forEach(notice => {
        notice.isSelected = checked;
      });
    },
    async removeNoticeList() {
      try {
        this.setIsLoading(true);

        const checkedList = this.noticeList?.filter(notice => notice.isSelected).map(notice => notice.id);
        if (!checkedList.length) {
          alert('삭제할 공지사항을 선택해주세요.');
          return;
        }

        const params = {
          id: checkedList,
        };
        await NoticeService.removeNoticeList(params);

        alert('삭제되었습니다.');
        await this.getNoticeList();
      } catch (error) {
        alert(error);
      } finally {
        this.setIsLoading(false);
      }
    },
  },
  created() {
    this.getNoticeList();
  },
};
</script>

<style scoped>
.notice-mgmt-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.notice-list-wrapper {
  background: var(--White);
  min-height: 560px;
}

.header-title-text > span {
  color: #969696;
  font-size: 16px;
  font-weight: 400;
  margin-left: 70px;
}

.notice-table {
  min-height: 460px;
}

.update-btn {
  width: 40px;
  height: 27px;
}
</style>
