<template>
  <div class="date-wrapper">
    <VueDatePicker
      ref="picker"
      :locale="locale"
      :modelValue="dateRange"
      @update:modelValue="updateDate"
      @keydown.esc="clearDuration"
      :format="format"
      :range="range"
      :multiCalendars="range"
      :readonly="readonly || disabled"
      :disabled="disabled"
      :enableTimePicker="time"
      :allowedDates="allowedDates"
      :weekStart="weekStart"
      textInput
      v-bind="attrs"
    />
  </div>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
  name: 'CruiseDatePicker',
  emits: ['update:dateRange'],
  components: { VueDatePicker },
  props: {
    dateRange: {
      type: [Array, Date, String],
    },
    label: {
      type: String,
      default: '',
    },
    time: {
      type: Boolean,
      default: false,
    },
    range: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    allowedDates: {
      type: Array,
    },
    weekStart: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  computed: {
    attrs() {
      const { class: className, ...rest } = this.$attrs;
      return rest;
    },
    format() {
      const timeFormat = this.time ? ' HH:mm' : '';
      return `yyyy/MM/dd${timeFormat}`;
    },
    minWidth() {
      const rangeWidth = this.range ? 150 : 130;
      const timeWidth = (this.time ? 43 : 0) * (this.range ? 2 : 1);
      return `${rangeWidth + timeWidth}px`;
    },
    locale() {
      const localeMap = {
        ko: 'ko-KR',
        en: 'en-US',
        ja: 'ja-JP',
      };
      return localeMap[this.$i18n.locale];
    },
  },
  methods: {
    updateDate(dateRange) {
      this.$emit('update:dateRange', dateRange);
    },
    clearDuration() {
      this.$refs.picker.closeMenu();
      this.$emit('update:dateRange', this.range ? [] : null);
    },
  },
};
</script>

<style scoped>
.date-wrapper {
  min-width: v-bind(minWidth);
  height: 40px;
  border-radius: 5px;
  border: 1px solid var(--Grey-grey-100, #dddfe2);
  background: #fff;
}

.dp__main.dp__theme_light {
  flex: 1 0 0;
  width: 100%;
  height: 100%;
  --dp-border-color: none;
  border-radius: 5px;
}

.dp__main.dp__theme_light > :first-child,
:deep(.dp__input_wrap),
:deep(.dp__input) {
  width: 100%;
  height: 100%;
}

:deep(.dp__input) {
  font-size: 14px;
  color: var(--Grey-grey-900);
}

:deep(.dp__input:disabled) {
  background: none;
}

:deep(input::placeholder) {
  color: var(--Grey-grey-900);
}

:deep(input:disabled::placeholder) {
  color: var(--Grey-grey-200);
}
</style>
