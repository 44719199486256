<template>
  <div class="textarea-wrapper">
    <textarea
      class="cruise-textarea text-ellipsis"
      :class="[disabledClass, readonlyClass]"
      :id="safeId"
      v-bind="attrs"
      placeholder=""
      :value="modelValue"
      :maxlength="maxlength"
      @blur="handleBlur"
      @focus="handleFocus"
      @input="handleInput"
      :disabled="disabled"
      :readonly="readonly"
    />
    <slot></slot>
    <label v-if="emptyValue && attrs.placeholder" class="input-placeholder text-ellipsis" :for="safeId">
      {{ attrs.placeholder }}<span v-if="required" class="required-star">*</span>
    </label>
  </div>
</template>

<script>
import { sharedComputedProps } from '@/mixins/formMixins';

export default {
  name: 'CruiseTextArea',
  emits: ['update:modelValue', 'onBlur', 'onFocus', 'onInput'],
  mixins: [sharedComputedProps],
  props: {
    modelValue: [String, Number],
    required: Boolean,
    isError: Boolean,
    regexFilter: [Object, String],
    regexReplace: {
      type: String,
      default: '',
    },
    maxlength: {
      type: Number,
      default: 1000,
    },
    disabled: Boolean,
    readonly: Boolean,
  },
  computed: {
    emptyValue() {
      return this.modelValue === '' || this.modelValue === null || this.modelValue === undefined;
    },
    disabledClass() {
      return { disabled: this.disabled };
    },
    readonlyClass() {
      return { readonly: this.readonly };
    },
  },
  methods: {
    handleBlur(e) {
      this.$emit('onBlur', e);
    },
    handleFocus(e) {
      this.$emit('onFocus', e);
    },
    handleInput(e) {
      const filteredValue = e.target.value.replace(this.regexFilter, this.regexReplace);

      const sanitizedValue = this.$sanitize(filteredValue);
      e.target.value = sanitizedValue;

      this.$emit('update:modelValue', e.target.value);
      this.$emit('onInput', e);
    },
  },
};
</script>

<style scoped>
.textarea-wrapper {
  position: relative;
  width: 100%;
  height: 80px;
  border-radius: 5px;
  border: var(--default--border);
  background: var(--White);
  display: flex;
  align-items: center;
  padding: 10px;
}

.cruise-textarea {
  font-family: 'Pretendard';
  flex: 1 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  resize: none;
  white-space: pre;
  word-spacing: normal;
  letter-spacing: normal;
  background: transparent;
  font-size: 12px;
  color: var(--Black);
  border-radius: 5px;
  margin: 0;
  padding: 0;
  overflow: auto;
}

.input-placeholder {
  display: inline-block;
  width: calc(100% - 20px);
  position: absolute;
  top: 20px;
  left: 10px;
  transform: translateY(-50%);
  font-size: 12px;
  color: #969696;
  user-select: none;
}

.required-star {
  font-size: 12px;
  color: var(--Red);
}

.readonly {
  color: var(--Grey);
}

@media screen and (min-width: 768px) {
  .cruise-textarea {
    font-size: 14px;
  }

  .input-placeholder {
    font-size: 14px;
  }

  .required-star {
    font-size: 14px;
  }
}
</style>
