<template>
  <div class="event-register-wrapper flex-column">
    <h1 class="page-title">이벤트 {{ eventId ? '수정' : '등록' }}</h1>

    <div class="event-contents-wrapper">
      <CruiseInputField label="이벤트명" required>
        <div class="field-wrapper">
          <CruiseInput class="input-width" placeholder="한국어 입력" v-model="event.nameKo" />
          <CruiseInput class="input-width" placeholder="일본어 입력" v-model="event.nameJa" />
          <CruiseInput class="input-width" placeholder="영어 입력" v-model="event.nameEn" />
        </div>
      </CruiseInputField>

      <CruiseInputField label="노출 대상" required>
        <div class="field-wrapper">
          <CruiseCheckbox class="checkbox-width" label="한국어" v-model="event.isOpenedKo" />
          <CruiseCheckbox class="checkbox-width" label="일본어" v-model="event.isOpenedJa" />
          <CruiseCheckbox class="checkbox-width" label="영어" v-model="event.isOpenedEn" />
        </div>
      </CruiseInputField>

      <CruiseInputField label="기간" required>
        <div class="field-wrapper">
          <CruiseDatePicker
            class="input-width middle"
            v-model="startDateTime"
            placeholder="시작 기간"
            time
            enableTimePicker
          />
          ~
          <CruiseDatePicker
            class="input-width middle"
            v-model="endDateTime"
            placeholder="종료 기간"
            time
            enableTimePicker
          />
          <CruiseCheckbox class="checkbox-width" label="무제한" v-model="event.isUnlimited" />
        </div>
      </CruiseInputField>

      <CruiseInputField label="상태 설정" required>
        <div class="field-wrapper">
          <CruiseRadio
            name="status"
            v-for="item in statusList"
            :key="item.isOn"
            :label="getLocalText(item, 'status')"
            :value="item.isOn"
            v-model="event.isOn"
          />
        </div>
      </CruiseInputField>

      <CruiseInputField label="노출 설정" required>
        <div class="field-wrapper">
          <CruiseRadio
            name="status"
            v-for="item in exposureStatusList"
            :key="item.isExposed"
            :label="getLocalText(item, 'status')"
            :value="item.isExposed"
            v-model="event.isExposed"
          />
        </div>
      </CruiseInputField>

      <CruiseInputField label="분류" required>
        <div class="field-wrapper">
          <CruiseSelect
            class="select-width"
            :placeholder="getLocalText(categoryList, 'category')"
            :options="categoryList"
            v-model="category"
          >
            <template #option="{ item }">
              {{ getLocalText(item, 'category') }}
            </template>
          </CruiseSelect>
        </div>
      </CruiseInputField>

      <CruiseInputField label="이벤트 상세">
        <div class="event-detail">
          <div class="field-wrapper">
            <CruiseTabs v-model:activeTab="activeTab" theme="group">
              <CruiseTab v-for="(tab, i) in tabList" :title="tab.label" :key="tab.code">
                <div class="tab-panel">
                  <div class="cover-img-wrapper">
                    <div class="flex-text header">
                      <div class="field-label large">커버 이미지</div>
                      <span class="desc-text">권장 사이즈: 600px*400px(6:4)</span>
                    </div>

                    <div class="flex-text">
                      <div v-if="getImageUrl('cover', tab.code)" class="field-label large">
                        <a :href="getImageUrl('cover', tab.code)" target="_blank">
                          {{ getImageFileName(getImageUrl('cover', tab.code)) }}
                        </a>
                      </div>

                      <CruiseButton @click="openFileInput('cover', tab.code)">
                        이미지 {{ getImageUrl('cover', tab.code) ? '변경' : '등록' }}
                      </CruiseButton>
                      <input
                        type="file"
                        :ref="`coverImageFileInput${tab.code}`"
                        @change="uploadImage($event, 'cover', tab.code)"
                        accept=".svg, .jpg, .jpeg, .png"
                        style="display: none"
                      />
                      <div class="desc-text large">JPG, PNG, SVG 파일 확장자만 등록이 가능합니다.</div>
                    </div>
                  </div>

                  <div>
                    <div class="flex-text header">
                      <div class="field-label large">상세 내용 이미지<span class="required-star">*</span></div>
                      <div class="desc-text">권장 가로 사이즈: 1352px</div>
                    </div>

                    <div class="image-upload-wrapper flex-center">
                      <CruiseButton class="img-upload-btn" @click="openFileInput('main', tab.code)">
                        이미지 {{ getImageUrl('main', tab.code) ? '변경' : '등록' }}
                      </CruiseButton>
                      <input
                        type="file"
                        :ref="`mainImageFileInput${tab.code}`"
                        @change="uploadImage($event, 'main', tab.code)"
                        accept=".svg, .jpg, .jpeg, .png"
                        style="display: none"
                      />
                      <div v-if="getImageUrl('main', tab.code)" class="field-label large">
                        <a :href="getImageUrl('main', tab.code)" target="_blank">
                          {{ getImageFileName(getImageUrl('main', tab.code)) }}
                        </a>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div class="flex-text header">
                      <div class="field-label large">
                        <span>링크 또는 쿠폰선택</span>
                      </div>
                      <TextButton>
                        <a href="https://www.image-map.net/" target="_blank">좌표 찾기</a>
                      </TextButton>
                      <div class="desc-text">좌표 예시: coords="24,562,435,607"의 24,562,435,607 만 좌표에 입력</div>
                    </div>
                    <div>
                      <ul>
                        <li class="event_detail_list" v-for="(item, i) in getEventImageDetail(tab.code)">
                          <div class="list_content">
                            <div class="list_coords">
                              <span class="list_title">좌표</span>
                              <div>
                                <CruiseInput
                                  class="input-width middle"
                                  placeholder="예) 67,278,197,323"
                                  v-model="item.coordinates"
                                  type="coordinates"
                                />
                              </div>
                            </div>

                            <div class="list_detail link">
                              <CruiseRadio name="action" label="링크" :key="true" :value="true" v-model="item.isUrl" />
                              <div>
                                <CruiseInput
                                  class="input-width"
                                  placeholder="예) https://www.panstarcruise.com/"
                                  v-model="item.linkUrl"
                                  :disabled="!item.isUrl"
                                />
                              </div>
                            </div>

                            <div class="list_detail coupon">
                              <CruiseRadio
                                name="action"
                                label="쿠폰"
                                :key="false"
                                :value="false"
                                v-model="item.isUrl"
                              />

                              <div v-if="item.couponCode" class="coupon_name">
                                <span class="coupon_text text-ellipsis">{{ item.couponName }} </span>
                                <span class="clear-btn" @click="removeCoupon(item)">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="none"
                                  >
                                    <circle cx="7" cy="7" r="7" fill="#D9D9D9" />
                                    <path
                                      d="M9.97481 4.03149C9.79076 3.84744 9.49346 3.84744 9.30941 4.03149L7.00174 6.33444L4.69406 4.02677C4.51002 3.84272 4.21271 3.84272 4.02866 4.02677C3.84461 4.21082 3.84461 4.50812 4.02866 4.69217L6.33633 6.99984L4.02866 9.30752C3.84461 9.49156 3.84461 9.78887 4.02866 9.97292C4.21271 10.157 4.51002 10.157 4.69406 9.97292L7.00174 7.66525L9.30941 9.97292C9.49346 10.157 9.79076 10.157 9.97481 9.97292C10.1589 9.78887 10.1589 9.49156 9.97481 9.30752L7.66714 6.99984L9.97481 4.69217C10.1541 4.51284 10.1541 4.21082 9.97481 4.03149Z"
                                      fill="white"
                                    />
                                  </svg>
                                </span>
                              </div>

                              <CruiseButton
                                v-if="!item.couponCode"
                                class="select-width"
                                :theme="couponButtonTheme(item)"
                                @click="openCouponModal(item, i)"
                                :disabled="item.isUrl"
                              >
                                선택하기
                              </CruiseButton>
                            </div>
                          </div>

                          <div class="list_buttons">
                            <CruiseButton
                              v-if="canAddList(tab.code, i)"
                              class="button"
                              theme="outline"
                              @click="addList(tab.code)"
                            >
                              + 추가
                            </CruiseButton>

                            <CruiseButton
                              v-if="canRemoveList(tab.code, i)"
                              theme="danger"
                              class="button"
                              @click.stop="removeList(tab.code, i)"
                            >
                              삭제
                            </CruiseButton>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </CruiseTab>
            </CruiseTabs>

            <CouponListModal
              v-if="isOpenCouponModal"
              v-model:showModal="isOpenCouponModal"
              :currentLanguage="currentTabLanguage"
              @selectCoupon="setSelectedCoupon($event, getEventImageDetail(activeTabCode))"
            />
          </div>
        </div>
      </CruiseInputField>
    </div>

    <div class="btn-wrapper">
      <CruiseButton class="select-width" @click="saveEvent">{{ eventId ? '수정' : '저장' }}</CruiseButton>
      <CruiseButton class="select-width" theme="s" @click="cancel">취소</CruiseButton>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { YYYYMMDD, HH_MM } from '@/const/const';
import { mapMutations, mapState } from 'vuex';
import { uploadFileToAzure } from '@/utils/azure';
import { getLocalKey, getLocalText, formatDate } from '@/utils/convert';

import EventService from '@/services/event';

import CruiseInputField from '@/components/common/CruiseInputField.vue';
import CruiseInput from '@/components/common/CruiseInput.vue';
import CruiseCheckbox from '@/components/common/CruiseCheckbox.vue';
import CruiseSelect from '@/components/common/CruiseSelect.vue';
import CruiseButton from '@/components/button/CruiseButton.vue';
import CruiseDatePicker from '@/components/common/CruiseDatePicker.vue';
import CruiseRadio from '@/components/common/CruiseRadio.vue';
import CruiseTabs from '@/components/tab/CruiseTabs.vue';
import CruiseTab from '@/components/tab/CruiseTab.vue';
import TextButton from '@/components/button/TextButton.vue';
import CouponListModal from '@/components/event/CouponListModal.vue';

export default {
  name: 'EventRegister',
  components: {
    CruiseInputField,
    CruiseInput,
    CruiseCheckbox,
    CruiseSelect,
    CruiseButton,
    CruiseDatePicker,
    CruiseRadio,
    CruiseTabs,
    CruiseTab,
    TextButton,
    CouponListModal,
  },
  data() {
    return {
      activeTab: 0,

      categoryList: [
        { categoryKo: '혜택', categoryJa: '特典', categoryEn: 'Benefit' },
        { categoryKo: '소식', categoryJa: 'ニュース', categoryEn: 'News' },
      ],
      statusList: [
        { isOn: true, statusKo: '진행중', statusJa: '進行中', statusEn: 'On' },
        { isOn: false, statusKo: '종료', statusJa: '終了', statusEn: 'Off' },
      ],
      exposureStatusList: [
        { isExposed: true, statusKo: '노출', statusJa: '露出', statusEn: 'Exposed' },
        { isExposed: false, statusKo: '비노출', statusJa: '非露出', statusEn: 'Non-exposed' },
      ],
      tabList: [
        { code: 'Ko', label: '한국어' },
        { code: 'Ja', label: '일본어' },
        { code: 'En', label: '영어' },
      ],

      startDateTime: dayjs().startOf('day'),
      endDateTime: dayjs().startOf('day'),
      category: { categoryKo: '혜택', categoryJa: '特典', categoryEn: 'Benefit' },

      event: {
        nameKo: '',
        nameJa: '',
        nameEn: '',
        isOpenedKo: true,
        isOpenedJa: true,
        isOpenedEn: true,
        startDate: '',
        startTime: '',
        endDate: '',
        endTime: '',
        isUnlimited: false,
        isOn: true,
        statusKo: '진행중',
        statusJa: '進行中',
        statusEn: 'on',
        categoryKo: '혜택',
        categoryJa: '特典',
        categoryEn: 'Benefit',
        coverImageUrlKo: '',
        mainImageUrlKo: '',
        coverImageUrlJa: '',
        mainImageUrlJa: '',
        coverImageUrlEn: '',
        mainImageUrlEn: '',
        isExposed: true,

        eventDetailsKo: [
          {
            language: 'ko',
            coordinates: '',
            isUrl: true,
            linkUrl: '',
            couponCode: '',
            couponName: '',
          },
        ],
        eventDetailsJa: [
          {
            language: 'ja',
            coordinates: '',
            isUrl: true,
            linkUrl: '',
            couponCode: '',
            couponName: '',
          },
        ],
        eventDetailsEn: [
          {
            language: 'en',
            coordinates: '',
            isUrl: true,
            linkUrl: '',
            couponCode: '',
            couponName: '',
          },
        ],
      },

      isOpenCouponModal: false,
      selectedCoupon: null,
      selectedIdx: null,
    };
  },
  computed: {
    ...mapState('auth', ['user']),

    eventId() {
      return this.$route.query.id;
    },
    activeTabCode() {
      return this.tabList[this.activeTab].code;
    },
    currentTabLanguage() {
      return this.activeTabCode.toLowerCase();
    },
  },
  methods: {
    ...mapMutations('common', ['setIsLoading']),
    getLocalKey,
    getLocalText,

    init() {
      this.getEventDetail();
    },
    async getEventDetail() {
      if (!this.eventId) return;

      try {
        this.setIsLoading(true);

        const { data } = await EventService.getEventDetail(this.eventId);

        this.event = {
          ...data,
          eventDetailsKo: !data.eventDetailsKo ? this.event.eventDetailsKo : data.eventDetailsKo,
          eventDetailsJa: !data.eventDetailsJa ? this.event.eventDetailsJa : data.eventDetailsJa,
          eventDetailsEn: !data.eventDetailsEn ? this.event.eventDetailsEn : data.eventDetailsEn,
        };

        const startTime = data.startTime || '00:00';
        const endTime = data.endTime || '00:00';
        this.startDateTime = dayjs(data.startDate + startTime, 'YYYYMMDDHH:mm');
        this.endDateTime = dayjs(data.endDate + endTime, 'YYYYMMDDHH:mm');

        const categoryTextByLocal = getLocalKey('category');
        this.category = this.categoryList.find(item => item[categoryTextByLocal] === data[categoryTextByLocal]);
      } catch (error) {
        alert(error);
      } finally {
        this.setIsLoading(false);
      }
    },
    canSave() {
      if (!this.event.isOpenedKo && !this.event.isOpenedJa && !this.event.isOpenedEn) {
        alert('노출 대상 언어를 1개 이상 선택해주세요.');
        return false;
      }

      if (this.event.isOpenedKo) {
        if (!this.event.nameKo) {
          alert('한국어 이벤트명을 입력해주세요.');
          return false;
        }

        if (this.event.isExposed) {
          if (!this.event.coverImageUrlKo || !this.event.mainImageUrlKo) {
            alert('한국어 탭의 이벤트 상세 이미지를 모두 등록해주세요.');
            return false;
          }
        } else {
          if (!this.event.mainImageUrlKo) {
            alert('한국어 탭의 상세 내용 이미지를 등록해주세요.');
            return false;
          }
        }

        return this.event?.eventDetailsKo.every(detail => {
          const hasCoordinate = detail.coordinates;
          const hasLinkUrl = detail.isUrl && detail.linkUrl;
          const hasCoupon = !detail.isUrl && detail.couponCode;

          if (!hasCoordinate && (hasLinkUrl || hasCoupon)) {
            alert('한국어 탭의 좌표를 모두 입력해주세요.');
            return false;
          }

          if (hasCoordinate && !hasLinkUrl && !hasCoupon) {
            alert('한국어 탭의 링크 혹은 쿠폰을 모두 등록해주세요.');
            return false;
          }

          return true;
        });
      }

      if (this.event.isOpenedJa) {
        if (!this.event.nameJa) {
          alert('일본어 이벤트명을 입력해주세요.');
          return false;
        }

        if (!this.event.mainImageUrlJa) {
          alert('일본어 이벤트 상세 내용 이미지를 등록해주세요.');
          return false;
        }

        if (this.event.isExposed) {
          if (!this.event.coverImageUrlJa || !this.event.mainImageUrlJa) {
            alert('일본어 탭의 이벤트 상세 이미지를 모두 등록해주세요.');
            return false;
          }
        } else {
          if (!this.event.mainImageUrlJa) {
            alert('일본어 탭의 상세 내용 이미지를 등록해주세요.');
            return false;
          }
        }

        return this.event?.eventDetailsJa.every(detail => {
          const hasCoordinate = detail.coordinates;
          const hasLinkUrl = detail.isUrl && detail.linkUrl;
          const hasCoupon = !detail.isUrl && detail.couponCode;

          if (!hasCoordinate && (hasLinkUrl || hasCoupon)) {
            alert('일본어 탭의 좌표를 모두 입력해주세요.');
            return false;
          }

          if (hasCoordinate && !hasLinkUrl && !hasCoupon) {
            alert('일본어 탭의 링크 혹은 쿠폰을 모두 등록해주세요.');
            return false;
          }

          return true;
        });
      }

      if (this.event.isOpenedEn) {
        if (!this.event.nameEn) {
          alert('영어 탭의 이벤트명을 입력해주세요.');
          return false;
        }

        if (this.event.isExposed) {
          if (!this.event.coverImageUrlEn || !this.event.mainImageUrlEn) {
            alert('영어 탭의 커버 이미지와 상세 내용 이미지를 모두 등록해주세요.');
            return false;
          }
        } else {
          if (!this.event.mainImageUrlEn) {
            alert('영어 탭의 상세 내용 이미지를 등록해주세요.');
            return false;
          }
        }

        return this.event?.eventDetailsEn.every(detail => {
          const hasCoordinate = detail.coordinates;
          const hasLinkUrl = detail.isUrl && detail.linkUrl;
          const hasCoupon = !detail.isUrl && detail.couponCode;

          if (!hasCoordinate && (hasLinkUrl || hasCoupon)) {
            alert('영어 탭의 좌표를 모두 입력해주세요.');
            return false;
          }

          if (hasCoordinate && !hasLinkUrl && !hasCoupon) {
            alert('영어 탭의 링크 혹은 쿠폰을 모두 등록해주세요.');
            return false;
          }

          return true;
        });
      }

      return true;
    },
    async saveEvent() {
      if (!this.canSave()) {
        return;
      }

      try {
        this.setIsLoading(true);

        const categoryTextByLocal = getLocalKey('category');
        const selectedCategory = this.categoryList.find(
          item => item[categoryTextByLocal] === this.category[categoryTextByLocal],
        );
        const selectedStatus = this.statusList.find(item => item.isOn === this.event.isOn);

        const isAllCoordsEmptyKo = this.event?.eventDetailsKo?.every(detail => !detail.coordinates);
        const isAllUrlEmptyKo = this.event?.eventDetailsKo?.every(detail => !detail.linkUrl);
        const isAllCouponEmptyKo = this.event?.eventDetailsKo?.every(detail => !detail.couponCode);

        const isAllCoordsEmptyJa = this.event?.eventDetailsJa?.every(detail => !detail.coordinates);
        const isAllUrlEmptyJa = this.event?.eventDetailsJa?.every(detail => !detail.linkUrl);
        const isAllCouponEmptyJa = this.event?.eventDetailsJa?.every(detail => !detail.couponCode);

        const isAllCoordsEmptyEn = this.event?.eventDetailsEn?.every(detail => !detail.coordinates);
        const isAllUrlEmptyEn = this.event?.eventDetailsEn?.every(detail => !detail.linkUrl);
        const isAllCouponEmptyEn = this.event?.eventDetailsEn?.every(detail => !detail.couponCode);

        this.event = {
          ...this.event,

          id: this.eventId ? Number(this.eventId) : null,

          updatedUser: this.eventId ? this.user.userId : null,
          createdUser: this.eventId ? null : this.user.userId,

          startDate: formatDate(this.startDateTime, YYYYMMDD),
          startTime: formatDate(this.startDateTime, HH_MM),
          endDate: formatDate(this.endDateTime, YYYYMMDD),
          endTime: formatDate(this.endDateTime, HH_MM),

          statusKo: selectedStatus.statusKo,
          statusJa: selectedStatus.statusJa,
          statusEn: selectedStatus.statusEn,

          categoryKo: selectedCategory.categoryKo,
          categoryJa: selectedCategory.categoryJa,
          categoryEn: selectedCategory.categoryEn,

          eventDetailsKo:
            isAllCoordsEmptyKo && isAllUrlEmptyKo && isAllCouponEmptyKo ? null : this.event.eventDetailsKo,
          eventDetailsJa:
            isAllCoordsEmptyJa && isAllUrlEmptyJa && isAllCouponEmptyJa ? null : this.event.eventDetailsJa,
          eventDetailsEn:
            isAllCoordsEmptyEn && isAllUrlEmptyEn && isAllCouponEmptyEn ? null : this.event.eventDetailsEn,
        };

        if (this.eventId) {
          await EventService.setEvent(this.event);
        } else {
          await EventService.addEvent(this.event);
        }

        alert(`이벤트 ${this.eventId ? '수정' : '등록'}이 완료되었습니다.`);
        this.$router.push('/event');
      } catch (error) {
        alert(`이벤트 ${this.eventId ? '수정' : '등록'} 중 오류가 발생했습니다.`);
      } finally {
        this.setIsLoading(false);
      }
    },
    cancel() {
      const result = confirm('확인을 누르시면 작성한 내용은 저장되지 않습니다.\n취소하시겠습니까?');
      if (result) {
        this.$router.push('/event');
      }
    },
    getImageUrl(type, languageCode) {
      return this.event[`${type}ImageUrl${languageCode}`];
    },
    getImageFileName(fileUrl) {
      const lastSegment = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
      const cleanedFilename = lastSegment.replace(/^\d{21}/, '');

      return cleanedFilename;
    },
    openFileInput(type, languageCode) {
      const inputFile = `${type}ImageFileInput${languageCode}`;
      this.$refs[inputFile][0].click();
    },
    async uploadImage(event, type, languageCode) {
      const file = event.target.files[0];
      const fileUrl = await uploadFileToAzure(file, 'event');

      const imagePropertyName = `${type}ImageUrl${languageCode}`;
      this.event[imagePropertyName] = fileUrl;
    },
    getEventImageDetail(languageCode) {
      return this.event[`eventDetails${languageCode}`];
    },
    openCouponModal(item, i) {
      if (item.isUrl) {
        return;
      } else {
        this.selectedIdx = i;
        this.isOpenCouponModal = !this.isOpenCouponModal;
      }
    },
    couponButtonTheme(item) {
      return item.isUrl ? 's' : 'p';
    },
    canAddList(locale, index) {
      const currentEventDetailsLength = this.event[`eventDetails${locale}`].length;
      return index === currentEventDetailsLength - 1;
    },
    addList(locale) {
      const idx = 1;
      const currentEventDetails = this.event[`eventDetails${locale}`];
      const initialList = {
        language: locale.toLowerCase(),
        coordinates: '',
        isUrl: true,
        linkUrl: '',
        couponCode: '',
        couponName: '',
      };

      currentEventDetails.push(initialList);
    },
    canRemoveList(locale, index) {
      const currentEventDetailsLength = this.event[`eventDetails${locale}`].length;
      return index <= currentEventDetailsLength && index !== 0;
    },
    removeList(locale, index) {
      const currentEventDetails = this.event[`eventDetails${locale}`];
      currentEventDetails.splice(index, 1);
    },
    setSelectedCoupon(coupon, details) {
      details[this.selectedIdx].couponCode = coupon.couponCode;
      details[this.selectedIdx].couponName = coupon[`couponName${this.activeTabCode}`];
    },
    removeCoupon(item) {
      item.couponCode = '';
      item.couponName = null;
    },
  },
  created() {
    this.init();
  },
};
</script>

<style scoped>
.event-register-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 24px;
}

.event-contents-wrapper {
  display: flex;
  flex-direction: column;
}

.field-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 20px;
}

.input-width {
  width: 250px !important;
}

.input-width.middle {
  width: 190px !important;
}

.select-width {
  flex-shrink: 0;
  width: 120px;
}

.btn-wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.editor-wrapper {
  padding: 20px;
  height: 320px;
}

.event-detail {
  padding: 10px 0;
  width: 100%;
}

.tab-panel {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-top: 30px;
}

.flex-text {
  display: flex;
  align-items: center;
  gap: 10px;
}

.image-upload-wrapper {
  width: auto;
  height: 220px;
  border: 0.5px solid var(--Grey);
  flex-direction: column;
  gap: 10px;
}

.header {
  margin-bottom: 10px;
}

.desc-text {
  color: var(--Grey);
  font-size: 12px;
  font-weight: 400;
}

.field-label {
  font-size: 12px;
  font-weight: 400;
}

.field-label.large {
  font-size: 14px;
}

.desc-text {
  color: var(--Grey);
  font-size: 12px;
  font-weight: 400;
}

.desc-text.large {
  font-size: 14px;
}

.required-star {
  color: var(--Red);
}

.event_detail_list {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #dcdcdc;
}

.list_content {
  display: flex;
  gap: 22px;
  width: calc(100% - 110px);
}

.list_content .list_coords {
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 14px;
}

.list_content .list_detail {
  display: flex;
  gap: 16px;
}

.list_content .list_detail.coupon .coupon_name {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 12px;
  color: var(--Grey);
  margin-top: 1px;
}

.list_content .list_detail.coupon .coupon_name .coupon_text {
  max-width: 147px;
}

.list_content .list_detail.coupon .coupon_name .clear-btn {
  cursor: pointer;
}

.list_buttons {
  width: 110px;
  text-align: right;
  display: flex;
  align-items: flex-end;
  gap: 10px;
  flex-direction: column;
}

.list_buttons .button {
  width: 80px;
  height: 36px;
  font-weight: 600;
}
</style>
