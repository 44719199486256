import api from '@/services/api';

const API_URL = '/api/popUp';

class PopUpService {
  async getPopUp(id) {
    try {
      const response = await api.get(`${API_URL}/get`, { params: { id } });

      return response;
    } catch (error) {
      throw error;
    }
  }

  async getPopUpList(params) {
    try {
      const response = await api.get(`${API_URL}/getListManage`, { params });

      return response;
    } catch (error) {
      throw error;
    }
  }

  async createPopUp(params) {
    try {
      const response = await api.post(`${API_URL}/post`, params);

      return response;
    } catch (error) {
      throw error;
    }
  }

  async updatePopUp(params) {
    try {
      const response = await api.put(`${API_URL}/put`, params);

      return response;
    } catch (error) {
      throw error;
    }
  }

  async deletePopUp(params) {
    try {
      const response = await api.delete(`${API_URL}/delete`, { params });

      return response;
    } catch (error) {
      throw error;
    }
  }
}

export default new PopUpService();
