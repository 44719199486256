<template>
  <div class="user-nav" @click="isShowUserLnb">
    <div class="user-icon flex-center">
      <img src="@/assets/icons/user_icon.svg" alt="user icon" />
    </div>

    <div class="my-info">
      <b> {{ user?.name }} </b>님
    </div>

    <div v-if="isShow" class="user-lnb-wrapper">
      <div class="lnb-btn" @click="logout">로그아웃</div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'UserNav',
  data() {
    return {
      isShow: false,
    };
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  methods: {
    ...mapMutations('common', ['setIsLoading']),

    async logout() {
      this.setIsLoading(true);

      try {
        await this.$store.dispatch('auth/logout');
        this.$router.push('/login');
      } catch (error) {
        console.log('로그아웃 과정에서 오류가 발생했습니다.');
      } finally {
        this.setIsLoading(false);
      }
    },
    isShowUserLnb() {
      this.isShow = !this.isShow;
    },
  },
};
</script>

<style scoped>
.user-nav {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 120px;
  max-height: 40px;
  font-size: 14px;
  position: relative;
}

.user-icon {
  width: 24px;
  height: 24px;
  border-radius: 30px;
  margin-right: 10px;
}

.my-info {
  font-size: 14px;
  font-weight: 400;
  color: var(--Blue-blue-500);
}

.user-lnb-wrapper {
  position: absolute;
  top: 40px;
  right: 0;
  width: 100px;
  border-radius: 5px;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  background: var(--White);
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.25);
  padding: 10px;
  z-index: var(--zNavMenuPopover);
}

.lnb-btn {
  height: 32px;
  padding: 8px 10px;
  cursor: pointer;
}

.lnb-btn.my-page {
  border-bottom: var(--default--border);
}

@media screen and (min-width: 768px) {
  .user-info {
    width: 100%;
  }
}
</style>
