<template>
  <div class="tabs-wrapper">
    <CruiseDistributor tag="ul" :class="tabClass" :theme="theme" :header="true" :changeTabTo="changeTabTo">
      <slot></slot>
    </CruiseDistributor>

    <CruiseDistributor class="tab-contents">
      <slot></slot>
    </CruiseDistributor>
  </div>
</template>

<script>
import CruiseDistributor from '@/components/tab/CruiseDistributor.vue';

export default {
  name: 'CruiseTabs',
  emits: ['update:activeTab'],
  components: { CruiseDistributor },
  props: {
    activeTab: {
      type: Number,
      default: 0,
    },
    theme: {
      type: String,
      default: 'default',
      validator: value => ['default', 'group', 'blue'].includes(value),
    },
  },
  watch: {
    activeTab(val) {
      this.activeTabIndex = val || 0;
    },
  },
  data() {
    return {
      activeTabIndex: this.activeTab || 0,
    };
  },
  provide() {
    const tabs = {};

    Object.defineProperty(tabs, 'activeTab', {
      get: () => this.activeTabIndex,
    });
    return { tabs };
  },
  computed: {
    tabClass() {
      return this.theme === 'default' ? 'tab-nav' : `tab-nav ${this.theme}`;
    },
  },
  methods: {
    changeTabTo(tab) {
      this.activeTabIndex = tab;
      this.$emit('update:activeTab', tab);
    },
  },
};
</script>

<style scoped>
.tabs-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.tab-nav {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  background: var(--White);
  border-bottom: var(--default--border);
  padding: 0 8px;
}

/* 탭 테마 : 그룹 */
.tab-nav.group {
  padding: 0;
  height: 40px;
  border-bottom: none;
}

/* 탭 테마 : 블루 */
.tab-nav.blue {
  padding: 0;
  height: 40px;
}

.tab-contents {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 768px) {
  .tab-nav {
    padding: 0;
    height: 60px;
  }
}
</style>
