import api from '@/services/api';

const API_URL = '/api';

class AzureService {
  async getSasToken() {
    try {
      const response = await api.get(`${API_URL}/SASToken/get`);

      return response;
    } catch (error) {
      throw error;
    }
  }
}

export default new AzureService();
