export const decodeJWT = token => {
  const base64Url = token.split('.')[1]; // JWT의 Payload 부분 추출
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/'); // Base64Url을 Base64로 변환
  const decodedValue = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  ); // Base64 디코딩 및 URI 컴포넌트 디코딩
  return JSON.parse(decodedValue); // 디코딩된 문자열을 JSON 객체로 파싱
};
