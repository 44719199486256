<template>
  <div v-if="showModal" class="modal-background flex-center" @click="closeModal">
    <div class="modal-contents" v-bind="$attrs" @click.stop>
      <div class="modal-title">{{ title }}</div>
      <div v-if="showCloseIcon" role="button" class="close-button flex-center" @click="closeModal">
        <CloseIcon :size="21" />
      </div>

      <div class="contents">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import CloseIcon from 'vue-material-design-icons/Close';

export default {
  name: 'CruiseModal',
  inheritAttrs: false,
  emits: ['update:showModal', 'onClose'],
  components: { CloseIcon },
  props: {
    title: String,
    showModal: {
      type: Boolean,
      required: true,
    },
    showCloseIcon: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    closeModal() {
      this.$emit('update:showModal', false);
      this.$emit('onClose');
    },
  },
};
</script>

<style scoped>
.modal-background {
  position: fixed;
  z-index: var(--zLayoutModal);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(238, 244, 248, 0.6);
  backdrop-filter: blur(4px);
}

.modal-contents {
  position: relative;
  width: 70%;
  height: auto;
  background: var(--White);
  overflow: auto;
}

.modal-title {
  width: 100%;
  height: 60px;
  padding: 22px 16px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dcdcdc;
  color: var(--Black);
  font-size: 14px;
  font-weight: 400;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 21px;
  height: 21px;
  cursor: pointer;
}

.close-icon {
  width: 24px;
  height: 24px;
}

.contents {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  padding: 20px;
  background: var(--Background);
}

@media screen and (min-width: 768px) {
  .modal-contents {
    border-radius: 5px;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
  }
}
</style>
