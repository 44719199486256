<template>
  <div class="input-wrapper">
    <input
      class="cruise-input text-ellipsis"
      :class="disabledClass"
      :id="safeId"
      :type="inputType"
      v-bind="attrs"
      autocomplete="off"
      placeholder=""
      :value="modelValue"
      @blur="handleBlur"
      @focus="handleFocus"
      @input="handleInput"
      :disabled="disabled"
      :title="modelValue"
    />

    <slot></slot>

    <span v-if="clearBtn && isClearBtn" class="clear-btn" @click="clearInputValue">
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
        <circle cx="7" cy="7" r="7" fill="#D9D9D9" />
        <path
          d="M9.97481 4.03149C9.79076 3.84744 9.49346 3.84744 9.30941 4.03149L7.00174 6.33444L4.69406 4.02677C4.51002 3.84272 4.21271 3.84272 4.02866 4.02677C3.84461 4.21082 3.84461 4.50812 4.02866 4.69217L6.33633 6.99984L4.02866 9.30752C3.84461 9.49156 3.84461 9.78887 4.02866 9.97292C4.21271 10.157 4.51002 10.157 4.69406 9.97292L7.00174 7.66525L9.30941 9.97292C9.49346 10.157 9.79076 10.157 9.97481 9.97292C10.1589 9.78887 10.1589 9.49156 9.97481 9.30752L7.66714 6.99984L9.97481 4.69217C10.1541 4.51284 10.1541 4.21082 9.97481 4.03149Z"
          fill="white"
        />
      </svg>
    </span>
    <label v-if="emptyValue && attrs.placeholder" class="input-placeholder text-ellipsis" :for="safeId">
      {{ attrs.placeholder }}<span v-if="required" class="required-star">*</span>
    </label>
  </div>
</template>

<script>
import { sharedComputedProps } from '@/mixins/formMixins';

export default {
  name: 'CruiseInput',
  emits: ['update:modelValue', 'onBlur', 'onFocus', 'onInput'],
  mixins: [sharedComputedProps],
  props: {
    modelValue: [String, Number],
    required: Boolean,
    isError: Boolean,
    regexFilter: [Object, String],
    regexReplace: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearBtn: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    emptyValue() {
      return this.modelValue === '' || this.modelValue === null || this.modelValue === undefined;
    },
    inputType() {
      return this.$attrs.type || 'text';
    },
    disabledClass() {
      return {
        disabled: this.disabled,
      };
    },
    isClearBtn() {
      return this.modelValue !== '' && this.modelValue !== null && this.modelValue !== undefined;
    },
  },
  methods: {
    handleBlur(e) {
      this.$emit('onBlur', e);
    },
    handleFocus(e) {
      this.$emit('onFocus', e);
    },
    handleInput(e) {
      const filteredValue = e.target.value.replace(this.regexFilter, this.regexReplace);

      const sanitizedValue = this.$sanitize(filteredValue);
      
      if (
        this.inputType === 'userId' ||
        this.inputType === 'password' ||
        this.inputType === 'emailAddress' ||
        this.inputType === 'emailDomain' ||
        this.inputType === 'coordinates'
      ) {
        e.target.value = sanitizedValue.replaceAll(' ', '');
      } else {
        e.target.value = sanitizedValue;
      }

      this.$emit('update:modelValue', e.target.value);
      this.$emit('onInput', e);
    },
    clearInputValue() {
      this.$emit('update:modelValue', '');
    },
  },
};
</script>

<style scoped>
.input-wrapper {
  position: relative;
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: var(--default--border);
  background: var(--White);
  display: flex;
  align-items: center;
  padding: 0 10px;
}

.cruise-input {
  flex: 1 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background: transparent;
  font-size: 12px;
  color: var(--Black);
  border-radius: 5px;
}

.input-placeholder {
  display: inline-block;
  width: calc(100% - 20px);
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  font-size: 12px;
  color: #969696;
  user-select: none;
}

.required-star {
  font-size: 12px;
  color: var(--Red);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox  */
input[type='number'] {
  -moz-appearance: textfield;
}

.clear-btn {
  cursor: pointer;
  margin-left: 10px;
}

@media screen and (min-width: 768px) {
  .cruise-input {
    font-size: 14px;
  }

  .input-placeholder {
    font-size: 14px;
  }

  .required-star {
    font-size: 14px;
  }
}
</style>
