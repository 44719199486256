<template>
  <div>
    <table :class="tableClass">
      <thead>
        <tr>
          <template v-for="({ key, label, sorter, css }, idx) in theadList">
            <th v-if="key === 'checkbox'" :class="thClasses" class="all-check" :style="css">
              <CruiseCheckbox :checked="allChecked" @onChange="updateAllChecked" />
            </th>
            <th v-else :class="thClasses" :style="css">
              <div class="th-contents">
                <div class="column-label">
                  <div class="text-ellipsis">{{ label }}</div>
                </div>
                <span class="icon-wrapper" v-if="sorter" @click="changeSortState(key, idx)">
                  <span v-if="sortState.key === key">
                    <SortVariantOff class="sort-icon" :size="14" v-if="sortState.asc === null" />
                    <SortReverseVariant class="sort-icon" :size="14" v-else-if="sortState.asc" />
                    <SortVariant class="sort-icon" :size="14" v-else />
                  </span>
                  <SortVariantOff class="sort-icon" :size="14" v-else />
                </span>
              </div>
            </th>
          </template>
        </tr>
      </thead>

      <tbody>
        <template v-for="(item, idx) in sortedList">
          <TableBodyRow :item="item" :index="idx" :isSelected="item.isSelected" @clickRow="clickRow">
            <template v-for="{ key, css } in theadList">
              <td class="text-td text-ellipsis" :style="css" :class="tdClasses" :title="item[key]">
                <template v-if="$slots[key]">
                  <slot :name="key" :item="item" :index="idx"></slot>
                </template>
                <template v-else>
                  {{ item[key] }}
                </template>
              </td>
            </template>
          </TableBodyRow>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import SortVariant from 'vue-material-design-icons/SortVariant';
import SortReverseVariant from 'vue-material-design-icons/SortReverseVariant';
import SortVariantOff from 'vue-material-design-icons/SortVariantOff';
import Close from 'vue-material-design-icons/Close';
import Plus from 'vue-material-design-icons/Plus';

import DragVertical from '@/components/icons/DragVertical';
import TableBodyRow from '@/components/table/TableBodyRow.vue';
import CruiseCheckbox from '@/components/common/CruiseCheckbox.vue';

export default {
  name: 'CruiseTable',
  emits: ['clickRow', 'onAllCheck'],
  components: {
    TableBodyRow,
    SortVariant,
    SortReverseVariant,
    SortVariantOff,
    Close,
    Plus,
    DragVertical,
    CruiseCheckbox,
  },
  props: {
    theadList: Array,
    tbodyList: Array,
    theme: {
      type: String,
      default: 'basic',
      validator: val => ['basic', 'outline'].includes(val),
    },
    isFixed: {
      type: Boolean,
      default: false,
    },
    fontSize: {
      type: String,
      default: '14',
    },
    horizontal: Boolean,
    textAlign: {
      type: String,
      default: 'center',
      validator: val => ['start', 'center', 'end'].includes(val),
    },
  },
  data() {
    return {
      sortState: {
        key: null, // column key
        asc: null, // null, true, false
      },
      // allChecked: false,
    };
  },
  computed: {
    tableClass() {
      return [{ 'table-width': !this.horizontal }];
    },
    sortedList() {
      const { key, asc } = this.sortState;
      const flip = asc ? 1 : -1;
      if (asc === null) {
        return this.tbodyList;
      }
      return this.tbodyList.slice().sort((a, b) => {
        const first = typeof a[key] === 'number' ? a[key] : String(a[key]).toLocaleLowerCase();
        const second = typeof b[key] === 'number' ? b[key] : String(b[key]).toLocaleLowerCase();
        return first > second ? 1 * flip : second > first ? -1 * flip : 0;
      });
    },
    thClasses() {
      const style = {
        basic: 'basic-th',
        outline: 'outline-th',
      };
      return [{ fixed: this.isFixed }, style[this.theme]];
    },
    tdClasses() {
      const style = {
        basic: 'basic-td',
        outline: 'outline-td',
      };
      return style[this.theme];
    },
    cssFontSize() {
      return `${this.fontSize}px`;
    },
    allChecked() {
      return this.tbodyList?.length > 0 && this.tbodyList?.every(item => item.isSelected);
    },
  },
  methods: {
    changeSortState(key) {
      let { asc } = this.sortState;
      if (this.sortState.key === key) {
        asc = asc === null ? true : asc === true ? false : null;
      } else {
        asc = true;
      }
      this.sortState = { key, asc };
    },
    clickRow(item, idx, event) {
      this.$emit('clickRow', item, idx, event);
    },
    updateAllChecked(e) {
      this.$emit('onAllCheck', e.target.checked);
    },
  },
};
</script>

<style scoped>
table {
  border-collapse: collapse;
  table-layout: fixed;
  font-size: v-bind(cssFontSize);
}

.table-width {
  width: 100%;
}

.fixed {
  position: sticky;
  top: 0;
  z-index: var(--zContentsMain);
}

th {
  height: 40px;
  text-align: left;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.basic-th {
  background: var(--White);
  border-bottom: 0.5px solid var(--Black);
}

.outline-th {
  color: #0e0e2c;
  background: var(--LightBlue);
  border: var(--default--border);
}

.th-contents {
  width: 100%;
  height: 40px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.all-check {
  width: 50px;
  height: 40px;
  text-align: center;
  line-height: 100%;
}

.column-label {
  width: 100%;
  text-align: v-bind(textAlign);
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sort-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

td {
  height: 40px;
  text-align: v-bind(textAlign);
}

td.outline-td {
  border: var(--default--border);
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 30px;
  background-color: #8690a3;
}

::-webkit-scrollbar-track {
  background-color: var(--White);
}
</style>
