<template>
  <div class="event-register-wrapper flex-column">
    <h1 class="page-title">팝업 {{ popUpId ? '수정' : '등록' }}</h1>

    <div class="event-contents-wrapper">
      <CruiseInputField label="팝업명">
        <div class="field-wrapper">
          <CruiseInput class="input-width" placeholder="한국어 입력" v-model="popUp.titleKo" />
          <CruiseInput class="input-width" placeholder="일본어 입력" v-model="popUp.titleJa" />
          <CruiseInput class="input-width" placeholder="영어 입력" v-model="popUp.titleEn" />
        </div>
      </CruiseInputField>

      <CruiseInputField label="노출 대상">
        <div class="field-wrapper">
          <CruiseCheckbox class="checkbox-width" label="한국어" v-model="popUp.isOpenedKo" />
          <CruiseCheckbox class="checkbox-width" label="일본어" v-model="popUp.isOpenedJa" />
          <CruiseCheckbox class="checkbox-width" label="영어" v-model="popUp.isOpenedEn" />
        </div>
      </CruiseInputField>

      <CruiseInputField label="기간">
        <div class="field-wrapper">
          <CruiseDatePicker
            class="input-width middle"
            v-model="startDateTime"
            placeholder="시작 기간"
            time
            enableTimePicker
          />
          ~
          <CruiseDatePicker
            class="input-width middle"
            v-model="endDateTime"
            placeholder="종료 기간"
            time
            enableTimePicker
          />
          <CruiseCheckbox class="checkbox-width" label="무제한" v-model="popUp.isUnlimited" @onChange="handleDate" />
        </div>
      </CruiseInputField>

      <CruiseInputField label="상태 설정">
        <div class="field-wrapper">
          <CruiseRadio
            class="checkbox-width"
            name="status"
            v-for="item in statusList"
            :key="item.isUsed"
            :label="getLocalText(item, 'status')"
            :value="item.isUsed"
            v-model="popUp.isUsed"
          />
        </div>
      </CruiseInputField>

      <CruiseInputField label="이미지 상세">
        <div class="event-detail">
          <div class="field-wrapper">
            <CruiseTabs v-model:activeTab="activeTab" theme="group">
              <CruiseTab v-for="tab in tabList" :title="tab.label" :key="tab.code">
                <div class="tab-panel">
                  <div class="cover-img-wrapper">
                    <div class="flex-text header">
                      <div class="field-label large">팝업 이미지</div>
                      <span class="desc-text">권장 사이즈: 460px*680px</span>
                    </div>

                    <div class="flex-text">
                      <div v-if="getImageUrl(tab.code)" class="field-label large">
                        <a :href="getImageUrl(tab.code)" target="_blank">
                          {{ getImageFileName(getImageUrl(tab.code)) }}
                        </a>
                      </div>

                      <CruiseButton @click="openFileInput(tab.code)">
                        이미지 {{ getImageUrl(tab.code) ? '변경' : '등록' }}
                      </CruiseButton>
                      <input
                        type="file"
                        :ref="`imageFileInput${tab.code}`"
                        @change="uploadImage($event, tab.code)"
                        accept=".svg, .jpg, .jpeg, .png"
                        style="display: none"
                      />
                      <div class="desc-text large">JPG, PNG, SVG 파일 확장자만 등록이 가능합니다.</div>
                    </div>
                  </div>
                </div>
              </CruiseTab>
            </CruiseTabs>
          </div>
        </div>
      </CruiseInputField>
    </div>

    <div class="btn-wrapper">
      <CruiseButton class="select-width" @click="savePopUp">{{ popUpId ? '수정' : '저장' }}</CruiseButton>
      <CruiseButton class="select-width" theme="s" @click="cancel">취소</CruiseButton>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { YYYYMMDD, HH_MM } from '@/const/const';
import { mapMutations, mapState } from 'vuex';
import { uploadFileToAzure } from '@/utils/azure';
import { getLocalKey, getLocalText, formatDate } from '@/utils/convert';

import PopUpService from '@/services/popUp';

import CruiseInputField from '@/components/common/CruiseInputField.vue';
import CruiseInput from '@/components/common/CruiseInput.vue';
import CruiseCheckbox from '@/components/common/CruiseCheckbox.vue';
import CruiseSelect from '@/components/common/CruiseSelect.vue';
import CruiseButton from '@/components/button/CruiseButton.vue';
import CruiseDatePicker from '@/components/common/CruiseDatePicker.vue';
import CruiseRadio from '@/components/common/CruiseRadio.vue';
import CruiseTabs from '@/components/tab/CruiseTabs.vue';
import CruiseTab from '@/components/tab/CruiseTab.vue';

export default {
  name: 'PopUpRegister',
  components: {
    CruiseInputField,
    CruiseInput,
    CruiseCheckbox,
    CruiseSelect,
    CruiseButton,
    CruiseDatePicker,
    CruiseRadio,
    CruiseTabs,
    CruiseTab,
  },
  data() {
    return {
      activeTab: 0,

      statusList: [
        { isUsed: true, statusKo: '진행중', statusJa: '進行中', statusEn: 'On' },
        { isUsed: false, statusKo: '종료', statusJa: '終了', statusEn: 'Off' },
      ],
      tabList: [
        { code: 'Ko', label: '한국어' },
        { code: 'Ja', label: '일본어' },
        { code: 'En', label: '영어' },
      ],

      startDateTime: dayjs().startOf('day'),
      endDateTime: dayjs().startOf('day'),

      popUp: {
        titleKo: '',
        titleJa: '',
        titleEn: '',
        isOpenedKo: true,
        isOpenedJa: true,
        isOpenedEn: true,
        startDate: '',
        startTime: '',
        endDate: '',
        endTime: '',
        isUnlimited: false,
        isUsed: true,
        mainOpened: true,

        statusKo: '진행중',
        statusJa: '進行中',
        statusEn: 'on',

        imageUrlKo: '',
        imageUrlEn: '',
        imageUrlJa: '',
      },
    };
  },
  computed: {
    ...mapState('auth', ['user']),

    popUpId() {
      return this.$route.query.id;
    },
  },
  methods: {
    ...mapMutations('common', ['setIsLoading']),
    getLocalKey,
    getLocalText,

    init() {
      this.getPopUpDetail();
    },
    async getPopUpDetail() {
      if (!this.popUpId) return;

      try {
        this.setIsLoading(true);

        const { data } = await PopUpService.getPopUp(parseInt(this.popUpId));
        this.popUp = data;

        const startTime = data.startTime || '00:00';
        const endTime = data.endTime || '00:00';
        this.startDateTime = dayjs(data.startDate + startTime, 'YYYYMMDDHH:mm');
        this.endDateTime = dayjs(data.endDate + endTime, 'YYYYMMDDHH:mm');
      } catch (error) {
        alert(error);
      } finally {
        this.setIsLoading(false);
      }
    },
    canSave() {
      if (!this.popUp.isOpenedKo && !this.popUp.isOpenedJa && !this.popUp.isOpenedEn) {
        alert('노출 대상 언어를 1개 이상 선택해주세요.');
        return false;
      }

      if (this.popUp.isOpenedKo) {
        if (!this.popUp.titleKo) {
          alert('한국어 팝업명을 입력해주세요.');
          return false;
        }

        if (!this.popUp.imageUrlKo) {
          alert('한국어 팝업 상세 이미지를 등록해주세요.');
          return false;
        }
      }

      if (this.popUp.isOpenedJa) {
        if (!this.popUp.titleJa) {
          alert('일본어 팝업명을 입력해주세요.');
          return false;
        }

        if (!this.popUp.imageUrlJa) {
          alert('일본어 팝업 상세 이미지를 등록해주세요.');
          return false;
        }
      }

      if (this.popUp.isOpenedEn) {
        if (!this.popUp.titleEn) {
          alert('영어 팝업명을 입력해주세요.');
          return false;
        }

        if (!this.popUp.imageUrlEn) {
          alert('영어 팝업 상세 이미지를 등록해주세요.');
          return false;
        }
      }

      return true;
    },
    async savePopUp() {
      if (!this.canSave()) {
        return;
      }

      try {
        this.setIsLoading(true);

        const selectedStatus = this.statusList.find(item => item.isUsed === this.popUp.isUsed);

        this.popUp = {
          ...this.popUp,

          id: this.popUpId ? Number(this.popUpId) : null,

          updatedUser: this.popUpId ? this.user.userId : null,
          createdUser: this.popUpId ? null : this.user.userId,

          startDate: this.startDateTime ? formatDate(this.startDateTime, YYYYMMDD) : null,
          startTime: this.startDateTime ? formatDate(this.startDateTime, HH_MM) : null,
          endDate: this.endDateTime ? formatDate(this.endDateTime, YYYYMMDD) : null,
          endTime: this.endDateTime ? formatDate(this.endDateTime, HH_MM) : null,

          isUnlimited: this.startDateTime || this.endDateTime ? false : true,

          statusKo: selectedStatus.statusKo,
          statusJa: selectedStatus.statusJa,
          statusEn: selectedStatus.statusEn,
        };

        if (this.popUpId) {
          await PopUpService.updatePopUp(this.popUp);
        } else {
          await PopUpService.createPopUp(this.popUp);
        }

        alert(`이벤트 ${this.popUpId ? '수정' : '등록'}이 완료되었습니다.`);
        this.$router.push('/pop-up');
      } catch (error) {
        alert(`이벤트 ${this.popUpId ? '수정' : '등록'} 중 오류가 발생했습니다.`);
      } finally {
        this.setIsLoading(false);
      }
    },
    cancel() {
      const result = confirm('확인을 누르시면 작성한 내용은 저장되지 않습니다.\n취소하시겠습니까?');
      if (result) {
        this.$router.push('/pop-up');
      }
    },
    getImageUrl(languageCode) {
      return this.popUp[`imageUrl${languageCode}`];
    },
    getImageFileName(fileUrl) {
      const lastSegment = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
      const cleanedFilename = lastSegment.replace(/^\d{21}/, '');

      return cleanedFilename;
    },
    openFileInput(languageCode) {
      const inputFile = `imageFileInput${languageCode}`;
      this.$refs[inputFile][0].click();
    },
    async uploadImage(event, languageCode) {
      const file = event.target.files[0];
      const fileUrl = await uploadFileToAzure(file, 'popup');

      const imagePropertyName = `imageUrl${languageCode}`;
      this.popUp[imagePropertyName] = fileUrl;
    },
    handleDate() {
      if (this.popUp.isUnlimited) {
        this.startDateTime = null;
        this.endDateTime = null;
      } else {
        this.startDateTime = dayjs().startOf('day');
        this.endDateTime = dayjs().startOf('day');
      }
    },
  },
  created() {
    this.init();
  },
};
</script>

<style scoped>
.event-contents-wrapper {
  display: flex;
  flex-direction: column;
}

.field-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 20px;
}

.input-width {
  width: 250px !important;
}

.input-width.middle {
  width: 190px !important;
}

.select-width {
  flex-shrink: 0;
  width: 120px;
}

.btn-wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.editor-wrapper {
  padding: 20px;
  height: 320px;
}

.event-detail {
  padding: 10px 0;
  width: 100%;
}

.tab-panel {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-top: 30px;
}

.flex-text {
  display: flex;
  align-items: center;
  gap: 10px;
}

.image-upload-wrapper {
  width: auto;
  height: 220px;
  border: 0.5px solid #969696;
  flex-direction: column;
  gap: 10px;
}

.header {
  margin-bottom: 10px;
}

.desc-text {
  color: #969696;
  font-size: 12px;
  font-weight: 400;
}

.field-label {
  font-size: 12px;
  font-weight: 400;
}

.field-label.large {
  font-size: 14px;
}

.desc-text {
  color: #969696;
  font-size: 12px;
  font-weight: 400;
}

.desc-text.large {
  font-size: 14px;
}
</style>
