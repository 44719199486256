const state = {
  isLoading: false,
};

const mutations = {
  setIsLoading(state, value) {
    state.isLoading = value;
  },
};

const actions = {};

const getters = {};

const common = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default common;
