import { getItem, setItem } from '@/utils/localStorage';
import { createI18n } from 'vue-i18n';
import ko from '@/languages/ko.json';
import en from '@/languages/en.json';
import ja from '@/languages/ja.json';

const messages = { ko, en, ja };

const getBrowserLanguage = () => {
  const lang = navigator.language || navigator.languages[0];
  if (lang.startsWith('ko')) {
    return 'ko';
  } else if (lang.startsWith('ja')) {
    return 'ja';
  } else {
    return 'en';
  }
};

const userLanguage = getItem('userLanguage') || getBrowserLanguage();
setItem('userLanguage', userLanguage);

const i18n = createI18n({
  locale: userLanguage,
  globalInjection: true,
  fallbackLocale: 'en',
  messages,
});

export default i18n;
