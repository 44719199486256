<template>
  <button class="action-btn" :class="[btnClass, activeClass, disabledClass]" @keydown.enter.prevent :disabled="disabled">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'CruiseButton',
  props: {
    theme: {
      type: String,
      default: 'p',
      validator: value => ['p', 's', 't', 'q', 'outline', 'danger'].includes(value),
    },
    isActive: Boolean,
    disabled: Boolean,
  },
  computed: {
    btnClass() {
      return [`btn-${this.theme}`];
    },
    activeClass() {
      return this.isActive ? 'active' : '';
    },
    disabledClass () {
      return this.disabled ? 'disabled' : '';
    },
  },
};
</script>

<style scoped>
.action-btn {
  width: 100px;
  height: 40px;
  border-radius: 5px;
  text-align: center;
  font-size: 12px;
}

.btn-p {
  color: var(--White);
  background: var(--Main, #4c65b6);
}

.btn-s {
  color: var(--White);
  background: #9299a0;
}

.btn-t {
  border: var(--default--border);
  background: var(--White);
}

.btn-q {
  border: 0.5px solid #000;
  background: var(--White);
}

.btn-outline {
  gap: 4px;
  color: var(--Blue-blue-500);
  border: 1px solid var(--Blue-blue-500);
  background: var(--White);
}

.btn-outline:disabled {
  color: var(--Blue-blue-200);
  border: 1px solid var(--Blue-blue-200);
}

.btn-outline:hover,
.btn-outline:focus,
.btn-outline.active {
  color: var(--Blue-blue-700);
  border: 1px solid var(--Blue-blue-700);
}

.btn-outline:disabled:hover {
  color: var(--Blue-blue-200);
  border: 1px solid var(--Blue-blue-200);
  cursor: not-allowed;
}

.btn-danger {
  color: var(--White);
  background: var(--Orange-orange-500);
}

.btn-danger:disabled {
  background: var(--Orange-orange-200);
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger.active {
  background: var(--Orange-orange-600);
}

.btn-danger:disabled:hover {
  background: var(--Orange-orange-200);
  cursor: not-allowed;
}

.disabled{
  cursor: not-allowed;
}

@media screen and (min-width: 768px) {
  .action-btn {
    width: 120px;
    font-size: 14px;
  }
}
</style>
