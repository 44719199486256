import api from '@/services/api';

const API_URL = '/api/event';

class EventService {
  /** 이벤트 리스트 */
  async getEventList(params) {
    try {
      const response = await api.get(`${API_URL}/getListManage`, { params });

      return response;
    } catch (error) {
      throw error;
    }
  }

  /** 이벤트 디테일 */
  async getEventDetail(id) {
    try {
      const response = await api.get(`${API_URL}/get`, { params: { id } });

      return response;
    } catch (error) {
      throw error;
    }
  }

  /** 이벤트 등록 */
  async addEvent(params) {
    try {
      const response = await api.post(`${API_URL}/post`, params);

      return response;
    } catch (error) {
      throw error;
    }
  }

  /** 이벤트 수정 */
  async setEvent(params) {
    try {
      const response = await api.put(`${API_URL}/update`, params);

      return response;
    } catch (error) {
      throw error;
    }
  }

  /** 이벤트 삭제 */
  async removeEventList(params) {
    try {
      const response = await api.delete(`${API_URL}/delete`, { params });

      return response;
    } catch (error) {
      throw error;
    }
  }
}

export default new EventService();
