<template>
  <CruiseModal :showModal="showModal" @update:showModal="updateShowModal" title="쿠폰 목록" class="cancel-modal">
    <div class="coupon-list-wrapper">
      <div class="search-filter">
        <CruiseInput
          class="input-width"
          placeholder="검색어 입력"
          v-model="searchValue"
          @keyup.enter="searchCouponList"
        />

        <CruiseButton theme="s" @click="refresh">초기화</CruiseButton>
        <CruiseButton @click="searchCouponList">검색</CruiseButton>
      </div>

      <CruiseTabs v-model:activeTab="activeTab">
        <CruiseTab
          v-for="(label, i) in tabLabel"
          :title="`${label} ${couponData.length > 0 ? couponData[i].count : 0}`"
          :key="label"
        >
        </CruiseTab>
      </CruiseTabs>

      <div class="coupon-table">
        <CruiseTable :theadList="theadList" :tbodyList="coupon.coupons" @clickRow="selectCouponRow">
          <template #radio="{ item }">
            <CruiseRadio
              class="coupon_radio"
              :checked="selectedCoupon === item"
              @onChange="selectedCoupon = item"
              :disabled="isCouponDisabled(item)"
            />
          </template>
          <template #usedCount="{ item }">
            <div class="text-ellipsis" :title="usedCountLabel(item)">
              {{ usedCountLabel(item) }}
            </div>
          </template>
          <template #availableDate="{ item }">
            <div class="text-ellipsis" :title="availableDate(item)">
              {{ availableDate(item) }}
            </div>
          </template>
          <template #createdAt="{ item }">
            <div class="text-ellipsis" :title="formatDate(item.createdAt)">
              {{ formatDate(item.createdAt) }}
            </div>
          </template>
        </CruiseTable>
      </div>

      <CruisePagination :page="tabList[activeTab].page" :pages="coupon.pages" @update:page="handlePage" />
    </div>

    <div class="btn-wrapper">
      <CruiseButton class="bottom-btn" @click="selectCoupon">선택</CruiseButton>
      <CruiseButton class="bottom-btn" theme="s" @click="closeModal">취소</CruiseButton>
    </div>
  </CruiseModal>
</template>

<script>
import dayjs from 'dayjs';
import { mapMutations } from 'vuex';
import { YYYY_MM_DD_HH_MM_SS } from '@/const/const';
import { getLocalKey } from '@/utils/convert';
import CouponService from '@/services/coupon';

import CruiseModal from '@/components/modal/CruiseModal.vue';
import CruiseButton from '@/components/button/CruiseButton.vue';
import CruiseInput from '@/components/common/CruiseInput.vue';
import CruiseTab from '@/components/tab/CruiseTab.vue';
import CruiseTabs from '@/components/tab/CruiseTabs.vue';
import CruiseTable from '@/components/table/CruiseTable.vue';
import CruiseCheckbox from '@/components/common/CruiseCheckbox.vue';
import CruisePagination from '@/components/common/CruisePagination.vue';
import CruiseRadio from '@/components/common/CruiseRadio.vue';

export default {
  name: 'CouponListModal',
  emits: ['update:showModal', 'selectCoupon'],
  components: {
    CruiseModal,
    CruiseButton,
    CruiseInput,
    CruiseTabs,
    CruiseTab,
    CruiseTable,
    CruiseCheckbox,
    CruisePagination,
    CruiseRadio,
  },
  props: {
    showModal: Boolean,
    currentLanguage: String,
  },
  data() {
    return {
      limit: 10,
      activeTab: 0,
      isSearch: false,
      searchValue: null,

      tabLabel: ['전체', '대기', '진행중', '종료'],
      tabList: [
        { status: '', page: 1 },
        { status: 'PND', page: 1 },
        { status: 'IPG', page: 1 },
        { status: 'CMP', page: 1 },
      ],

      theadList: [
        { label: '선택', key: 'radio', css: { width: '50px' } },
        { label: '쿠폰명', key: this.getLocalKey('couponName'), css: { width: '200px' } },
        { label: '사용혜택', key: 'discountRate', css: { width: '70px' } },
        // { label: '사용조건', key: this.getLocalKey('conditionDescription'), css: { width: '200px' } },
        { label: '상태', key: this.getLocalKey('status'), css: { width: '100px' } },
        { label: '쿠폰형식', key: 'couponType', css: { width: '100px' } },
        { label: '사용/발행', key: 'usedCount', css: { width: '100px' } },
        { label: '사용기간', key: 'availableDate', css: { width: '200px' } },
        { label: '등록일', key: 'createdAt', css: { width: '150px' } },
      ],

      couponData: [],

      selectedCoupon: null,
    };
  },
  watch: {
    activeTab() {
      this.tabList.map(item => {
        item.page = 1;
      });
    },
  },
  computed: {
    coupon() {
      return this.couponData[this.activeTab] || {};
    },
  },
  methods: {
    ...mapMutations('common', ['setIsLoading']),

    getLocalKey,

    init() {
      this.getTotalCouponList();
    },
    async getCouponList() {
      try {
        this.setIsLoading(true);
        const item = this.tabList[this.activeTab];
        const query = {
          status: item.status,
          couponName: this.searchValue ? this.searchValue : null,
          page: item.page || 1,
          limit: this.limit,
          language: this.currentLanguage || 'ko',
        };
        const { data } = await CouponService.getCouponList(query);
        this.couponData[this.activeTab] = data;
        this.couponData[this.activeTab].coupons.forEach(coupon => {
          coupon.isSelected = false;
        });
      } catch (error) {
        alert(error);
        console.error(error);
      } finally {
        this.setIsLoading(false);
      }
    },
    async getTotalCouponList() {
      try {
        this.setIsLoading(true);
        const promises = this.tabList.map(item => {
          const query = {
            status: item.status,
            couponName: this.searchValue ? this.searchValue : null,
            page: item.page || 1,
            limit: this.limit,
            language: this.currentLanguage || 'ko',
          };
          return CouponService.getCouponList(query);
        });
        const results = await Promise.all(promises);
        this.couponData = results.map(res => res.data);
      } catch (error) {
        alert(error);
        console.error(error);
      } finally {
        this.setIsLoading(false);
      }
    },
    formatDate(date) {
      return dayjs(date).format(YYYY_MM_DD_HH_MM_SS);
    },
    availableDate({ availableDateFrom, availableDateTo }) {
      return availableDateFrom ? `${availableDateFrom} ~ ${availableDateTo}` : '제한없음';
    },
    usedCountLabel({ issuedCount, issuePerCouponCount }) {
      return issuePerCouponCount ? `${issuedCount}회/${issuePerCouponCount}회` : '제한없음';
    },
    handlePage(page) {
      this.tabList[this.activeTab].page = page;
      this.getCouponList();
    },
    refresh() {
      if (this.isSearch) {
        this.searchValue = null;
        this.isSearch = false;
        this.activeTab = 0;

        this.searchCouponList();
      }
    },
    async searchCouponList() {
      this.isSearch = true;
      this.tabList.map(item => {
        item.page = 1;
      });
      await this.getTotalCouponList();
      await this.getCouponList();
    },
    updateShowModal(value) {
      this.$emit('update:showModal', value);
    },
    closeModal() {
      this.updateShowModal(false);
    },
    selectCoupon() {
      this.$emit('selectCoupon', this.selectedCoupon);
      this.closeModal();
    },
    selectCouponRow(coupon) {
      if (this.isCouponDisabled(coupon)) {
        return;
      } else {
        this.selectedCoupon = coupon;
      }
    },
    isCouponDisabled(coupon) {
      const isCouponCompleted = coupon.statusEn === 'CMP';
      const hasNoRemainingIssuableCoupons = coupon.isLimited ? coupon.issuedCount >= coupon.issuePerCouponCount : false;

      const currentDate = dayjs();
      const isAfterToday = dayjs(coupon.availableDateTo).isAfter(currentDate);
      const isExpiryDateInvalid = coupon.availableDateFrom && !isAfterToday;

      return isCouponCompleted || hasNoRemainingIssuableCoupons || isExpiryDateInvalid;
    },
  },
  created() {
    this.init();
  },
};
</script>

<style scoped>
.coupon-list-wrapper {
  width: 100%;
  height: 100%;
  background: var(--White);
  position: relative;
  border-radius: 5px;
  background: #fff;
  overflow: hidden;
}

.search-filter {
  display: flex;
  gap: 10px;
  position: absolute;
  z-index: var(--zContentsMain);
  top: 10px;
  right: 20px;
}

.coupon-table {
  min-height: 460px;
  overflow: auto;
  overflow: auto;
}

.btn-wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.input-width {
  width: 250px;
}

.coupon_radio {
  margin-top: 3.5px;
}
</style>
