<template>
  <tr ref="row" :class="rowClass" :style="item.style" @click="clickRow(item, index, $event)">
    <slot />
  </tr>
</template>

<script>
export default {
  emits: ['clickRow'],
  props: {
    item: Object,
    index: Number,
    isSelected: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    rowClass() {
      return { selected: this.isSelected };
    },
  },
  methods: {
    clickRow(item, idx, event) {
      this.$emit('clickRow', item, idx, event);
    },
  },
};
</script>

<style scoped>
tr {
  border-bottom: var(--light--border);
  background: var(--White);
  cursor: pointer;
}

tr:hover {
  background: rgba(218, 226, 255, 0.2);
}

.selected {
  background: rgba(218, 226, 255, 0.7);
}
</style>
