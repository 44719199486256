<template>
  <div>
    <h2 class="page-title">팬스타 크루즈 관리자 페이지</h2>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
};
</script>

<style scoped></style>
