import api from '@/services/api';

const API_URL = '/api/packageTour';

class TourService {
  /** 투어 상품 리스트 */
  async getTourProductList(params) {
    try {
      const response = await api.get(`${API_URL}/getListManage`, { params });

      return response;
    } catch (error) {
      throw error;
    }
  }

  /** 투어 상품 디테일 */
  async getTourProductDetail(id) {
    try {
      const response = await api.get(`${API_URL}/get`, { params: { id } });

      return response;
    } catch (error) {
      throw error;
    }
  }

  /** 투어 상품 등급 리스트 */
  async getTourGradeList() {
    try {
      const response = await api.get(`${API_URL}/getGradeList`);

      return response;
    } catch (error) {
      throw error;
    }
  }

  /** 투어 상품 항차 리스트 */
  async getVoyageList(params) {
    try {
      const response = await api.get(`${API_URL}/getVoyagesInPeriod`, { params });

      return response;
    } catch (error) {
      throw error;
    }
  }

  /** 투어 상품 등록 */
  async addTourProduct(params) {
    try {
      const response = await api.post(`${API_URL}/post`, params);

      return response;
    } catch (error) {
      throw error;
    }
  }

  /** 투어 상품 수정 */
  async setTourProduct(id, params) {
    try {
      const response = await api.put(`${API_URL}/put`, params, { params: { id } });

      return response;
    } catch (error) {
      throw error;
    }
  }

  /** 투어 상품 삭제 */
  async removeTourProductList(params) {
    try {
      const response = await api.put(`${API_URL}/delete`, params);

      return response;
    } catch (error) {
      throw error;
    }
  }

  /** 투어 상품 항차별 세일코드 리스트 */
  async getSaleCodeList(params) {
    try {
      const response = await api.get(`${API_URL}/getSaleCodes`, { params });

      return response;
    } catch (error) {
      throw error;
    }
  }
}

export default new TourService();
