import api from '@/services/api';

const API_URL = '/api/notice';

class NoticeService {
  /** 공지사항 리스트 */
  async getNoticeList(params) {
    try {
      const response = await api.get(`${API_URL}/getListManage`, { params });

      return response;
    } catch (error) {
      throw error;
    }
  }

  /** 공지사항 디테일 */
  async getNoticeDetail(id) {
    try {
      const response = await api.get(`${API_URL}/get`, { params: { id } });

      return response;
    } catch (error) {
      throw error;
    }
  }

  /** 공지사항 등록 */
  async addNotice(params) {
    try {
      const response = await api.post(`${API_URL}/post`, params);

      return response;
    } catch (error) {
      throw error;
    }
  }

  /** 공지사항 수정 */
  async setNotice(params) {
    try {
      const response = await api.put(`${API_URL}/update`, params);

      return response;
    } catch (error) {
      throw error;
    }
  }

  /** 공지사항 삭제 */
  async removeNoticeList(params) {
    try {
      const response = await api.put(`${API_URL}/delete`, params);

      return response;
    } catch (error) {
      throw error;
    }
  }
}

export default new NoticeService();
