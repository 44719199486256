<template>
  <span
    v-bind="$attrs"
    :aria-hidden="!title"
    :aria-label="title"
    class="material-design-icon abugida-devanagari-icon"
    role="img"
    @click="$emit('click', $event)"
  >
    <svg :width="size" :height="size" class="material-design-icon__svg" viewBox="0 0 6 12" :fill="fillColor">
      <circle cx="1" cy="1" r="1" />
      <circle cx="1" cy="6" r="1" />
      <circle cx="1" cy="11" r="1" />
      <circle cx="5" cy="1" r="1" />
      <circle cx="5" cy="6" r="1" />
      <circle cx="5" cy="11" r="1" />
    </svg>
  </span>
</template>

<script>
export default {
  name: 'DragVertical',
  emits: ['click'],
  props: {
    title: {
      type: String,
    },
    fillColor: {
      type: String,
      default: 'currentColor',
    },
    size: {
      type: Number,
      default: 24,
    },
  },
};
</script>

<style></style>
