import i18n from '@/languages/i18n';
import dayjs from 'dayjs';
import { YYYY_MM_DD } from '@/const/const';

export const convertCommaNumber = num => {
  return `${new Intl.NumberFormat().format(num)}`;
};

export const capitalizeFirstLetter = string => {
  if (!string) return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getLocalKey = keyName => {
  const locale = capitalizeFirstLetter(i18n.global.locale);
  return `${keyName}${locale}`;
};

export const getLocalText = (obj, keyName) => {
  return obj[getLocalKey(keyName)];
};

export const formatDate = (date, format = YYYY_MM_DD) => {
  return dayjs(date).format(format);
};

export const formatDateRange = (startDate, endDate, format = YYYY_MM_DD) => {
  const formattedStartDate = dayjs(startDate).format(format);
  const formattedEndDate = dayjs(endDate).format(format);
  return `${formattedStartDate} ~ ${formattedEndDate}`;
};
