import api from '@/services/api';

const API_URL = '/api/coupon';

class CouponService {
  async getCouponList(params) {
    try {
      const response = await api.get(`${API_URL}/getCouponList`, { params });

      return response;
    } catch (error) {
      throw error;
    }
  }

  async getCouponById(couponId) {
    try {
      const response = await api.get(`${API_URL}/getCouponById`, { params: { couponId } });

      return response;
    } catch (error) {
      throw error;
    }
  }

  async getCouponType() {
    try {
      const response = await api.get(`${API_URL}/getCouponTypeList`);

      return response;
    } catch (error) {
      throw error;
    }
  }

  async getSaleCodeByRouteCode(routeCode) {
    try {
      const response = await api.get(`${API_URL}/getSaleCodeListByRouteCode`, { params: { routeCode } });

      return response;
    } catch (error) {
      throw error;
    }
  }

  async postCouponWithDetail(params) {
    try {
      const response = await api.post(`${API_URL}/postCouponWithDetail`, params);

      return response;
    } catch (error) {
      throw error;
    }
  }

  async updateCoupon(params) {
    try {
      const response = await api.patch(`${API_URL}/updateCoupon`, params);

      return response;
    } catch (error) {
      throw error;
    }
  }

  async deleteCoupon(params) {
    try {
      const response = await api.delete(`${API_URL}/deleteCouponList`, { params });

      return response;
    } catch (error) {
      throw error;
    }
  }
}

export default new CouponService();
