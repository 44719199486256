import api from '@/services/api';

const API_URL = '/api/grade';

class GradeService {
  async getGradeList() {
    try {
      const response = await api.get(`${API_URL}/getList`);

      return response;
    } catch (error) {
      throw error;
    }
  }
}

export default new GradeService();
