<template>
  <button class="text-btn">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'TextButton',
};
</script>

<style scoped>
.text-btn {
  color: var(--Blue-blue-500, #446ceb);
  font-size: 14px;
  font-weight: 400;
}

.text-btn:hover,
.text-btn:active {
  color: var(--Blue-blue-700, #304da7);
  text-decoration: underline;
}
</style>
