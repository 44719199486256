<template>
  <div class="qna-mgmt-wrapper">
    <h1 class="page-title">1:1 문의 관리</h1>

    <div class="qna-list-wrapper">
      <div class="search-filter">
        <CruiseSelect class="select-width" :options="searchList" v-model="searchType" property="key">
          <template #option="{ item }">
            {{ item.label }}
          </template>
        </CruiseSelect>

        <CruiseInput class="input-width" placeholder="검색어 입력" v-model="searchValue" @keyup.enter="searchQnaList" />

        <CruiseButton class="reset-btn" theme="s" @click="refresh">초기화</CruiseButton>
        <CruiseButton class="search-btn" @click="searchQnaList">검색</CruiseButton>

        <CruiseButton class="delete-btn" theme="q" @click="removeQnaList">삭제</CruiseButton>
      </div>

      <CruiseTabs v-model:activeTab="activeTab">
        <CruiseTab
          v-for="(tab, i) in tabLabel"
          :title="`${tab.label} ${qnaList.length > 0 ? qnaList[i][tab.key] : 0}`"
          :key="tab.key"
        >
        </CruiseTab>
      </CruiseTabs>

      <div class="qna-table">
        <CruiseTable :theadList="thead" :tbodyList="qna.qnas" @onAllCheck="handleAllCheck" @clickRow="goToUpdatePage">
          <template #checkbox="{ item }">
            <CruiseCheckbox v-model="item.isSelected" />
          </template>
          <template #category="{ item }">
            <div class="text-ellipsis" :title="item.category">
              {{ item.category.label }}
            </div>
          </template>
          <template #status="{ item }">
            <span v-if="item.status.code === 'Waiting'" class="status waiting text-ellipsis" :title="item.status.label">
              {{ item.status.label }}
            </span>
            <span v-else class="status answered text-ellipsis" :title="item.status.label">
              {{ item.status.label }}
            </span>
          </template>
          <template #answeredAt="{ item }">
            <div class="text-ellipsis" :title="item.answer?.createdAt">
              {{ item.answer?.createdAt }}
            </div>
          </template>
          <template #answerer="{ item }">
            <div class="text-ellipsis" :title="item.answer?.author">
              {{ item.answer?.author }}
            </div>
          </template>
          <template #remark="{ item }">
            <CruiseButton class="update-btn" theme="q" @click="goToUpdatePage(item)">답변</CruiseButton>
          </template>
        </CruiseTable>
      </div>
      <CruisePagination :page="tabList[activeTab].page" :pages="qna.pages" @update:page="handlePage" />
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { getLocalText, formatDateRange } from '@/utils/convert';

import QnaService from '@/services/qna';

import CruiseButton from '@/components/button/CruiseButton.vue';
import CruiseTabs from '@/components/tab/CruiseTabs.vue';
import CruiseTab from '@/components/tab/CruiseTab.vue';
import CruiseTable from '@/components/table/CruiseTable.vue';
import CruiseCheckbox from '@/components/common/CruiseCheckbox.vue';
import CruisePagination from '@/components/common/CruisePagination.vue';
import CruiseSelect from '@/components/common/CruiseSelect.vue';
import CruiseInput from '@/components/common/CruiseInput.vue';

export default {
  name: 'QnaManagement',
  components: {
    CruiseButton,
    CruiseTabs,
    CruiseTab,
    CruiseTable,
    CruiseCheckbox,
    CruisePagination,
    CruiseSelect,
    CruiseInput,
  },
  data() {
    return {
      activeTab: 0,

      qnaList: [],
      tabList: [
        { statusKo: '', statusJa: '', statusEn: null, page: 1 },
        { statusKo: '대기 중', statusJa: '待機中', statusEn: 'Waiting', page: 1 },
        { statusKo: '답변 완료', statusJa: '回答完了', statusEn: 'Solved', page: 1 },
      ],

      isSearch: false,
      searchValue: '',
      searchType: 'category',
      searchList: [
        { key: 'category', label: '문의 유형', value: '' },
        { key: 'content', label: '내용', value: '' },
        { key: 'userName', label: '질문자', value: '' },
      ],
    };
  },
  watch: {
    activeTab() {
      this.tabList.map(item => {
        item.page = 1;
      });
    },
  },
  computed: {
    tabLabel() {
      return [
        { key: 'count', label: '전체' },
        { key: 'countProceed', label: '대기 중' },
        { key: 'countEnd', label: '답변 완료' },
      ];
    },
    thead() {
      return [
        { key: 'checkbox' },
        { label: '문의 유형', key: 'category', css: { width: '120px' } },
        { label: '내용', key: 'content', css: { width: '300px' } },
        { label: '질문자', key: 'name', css: { width: '120px' } },
        { label: '답변 상태', key: 'status', css: { width: '120px' } },
        { label: '등록일', key: 'createdAt', css: { width: '130px' } },
        { label: '답변일', key: 'answeredAt', css: { width: '130px' } },
        { label: '답변자', key: 'answerer', css: { width: '120px' } },
        { label: '', key: 'remark', css: { width: '60px' } },
      ];
    },
    qna() {
      return this.qnaList[this.activeTab] || {};
    },
  },
  methods: {
    ...mapMutations('common', ['setIsLoading']),
    getLocalText,
    formatDateRange,

    init() {
      this.getTotalQnaList();
    },
    async getQnaList() {
      try {
        this.setIsLoading(true);

        const item = this.tabList[this.activeTab];
        const params = {
          category: this.searchType === 'category' ? this.searchValue : null,
          content: this.searchType === 'content' ? this.searchValue : null,
          name: this.searchType === 'userName' ? this.searchValue : null,
          status: item.statusEn,
          page: item.page || 1,
          limit: 10,
        };

        const { result } = await QnaService.getQnaList(params);
        this.qnaList[this.activeTab] = result;
      } catch (error) {
        alert(error);
      } finally {
        this.setIsLoading(false);
      }
    },
    async getTotalQnaList() {
      try {
        this.setIsLoading(true);

        const promises = this.tabList.map(item => {
          const params = {
            category: this.searchType === 'category' ? this.searchValue : null,
            content: this.searchType === 'content' ? this.searchValue : null,
            name: this.searchType === 'userName' ? this.searchValue : null,
            status: item.statusEn,
            page: item.page || 1,
            limit: 10,
          };

          return QnaService.getQnaList(params);
        });
        const results = await Promise.all(promises);
        this.qnaList = results.map(res => res.result);
      } catch (error) {
        alert(error);
        console.error(error);
      } finally {
        this.setIsLoading(false);
      }
    },
    async removeQnaList() {
      try {
        this.setIsLoading(true);

        const checkedList = this.qnaList[this.activeTab].qnas.filter(qna => qna.isSelected).map(qna => qna.id);

        if (!checkedList.length) {
          alert('삭제할 이벤트를 선택해주세요.');
          return;
        }

        const params = {
          ids: checkedList.join(','),
        };
        await QnaService.removeQnaList(params);

        alert('삭제되었습니다.');
        await this.getQnaList();
      } catch (error) {
        alert(error);
      } finally {
        this.setIsLoading(false);
      }
    },
    goToUpdatePage(qna) {
      this.$router.push({ path: '/qna/answer', query: { id: qna.id } });
    },
    handlePage(page) {
      this.tabList[this.activeTab].page = page;
      this.getQnaList();
    },
    handleAllCheck(checked) {
      this.qnaList[this.activeTab].qnas.forEach(qna => {
        qna.isSelected = checked;
      });
    },
    refresh() {
      if (this.isSearch) {
        window.location.reload();
        this.isSearch = false;
      }
    },
    async searchQnaList() {
      this.isSearch = true;
      this.tabList.map(item => {
        item.page = 1;
      });

      await this.getTotalQnaList();
      await this.getQnaList();
    },
  },
  created() {
    this.init();
  },
};
</script>

<style scoped>
.qna-mgmt-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.qna-list-wrapper {
  position: relative;
  border-radius: 5px;
  min-height: 560px;
  background: var(--White);
}

.status {
  font-weight: 700;
}

.status.waiting {
  color: var(--Red);
}

.status.answered {
  color: var(--Main);
}

.delete-btn {
  margin-left: 65px;
}

.header-title-text > span {
  color: #969696;
  font-size: 16px;
  font-weight: 400;
  margin-left: 70px;
}

.qna-table {
  min-height: 460px;
  overflow: auto;
}

.update-btn {
  width: 40px;
  height: 27px;
}

.search-filter {
  display: flex;
  gap: 10px;
  position: absolute;
  z-index: var(--zContentsMain);
  top: 10px;
  right: 20px;
}

.select-width {
  width: 120px;
}

.input-width {
  width: 250px;
}
</style>
