import api from '@/services/api';

const QNA_API_URL = '/api/qna';
const ANSWER_API_URL = '/api/answer';

class QnaService {
  /** 1:1 문의 리스트 */
  async getQnaList(params) {
    try {
      const response = await api.get(`${QNA_API_URL}/getListManage`, { params });

      return response;
    } catch (error) {
      throw error;
    }
  }

  /** 1:1 문의 디테일 */
  async getQnaDetail(id) {
    try {
      const response = await api.get(`${QNA_API_URL}/get`, { params: { id } });

      return response;
    } catch (error) {
      throw error;
    }
  }

  /** 1:1 문의 답변 조회 */
  async getAnswer(params) {
    try {
      const response = await api.post(`${ANSWER_API_URL}/get`, params);

      return response;
    } catch (error) {
      throw error;
    }
  }

  /** 1:1 문의 답변 등록 (이메일 자동 전송) */
  async addAnswer(params) {
    try {
      const response = await api.post(`${ANSWER_API_URL}/post`, params);

      return response;
    } catch (error) {
      throw error;
    }
  }

  /** 1:1 문의 삭제 */
  async removeQnaList(params) {
    try {
      const response = await api.delete(`${QNA_API_URL}/delete`, { params });

      return response;
    } catch (error) {
      throw error;
    }
  }
}

export default new QnaService();
